import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext';
import Header from '../Dashboard/Header/Header.jsx';
import Footer from '../Dashboard/Footer/Footer.jsx';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default ProtectedRoute;
