import React, {useEffect } from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter.jsx";
import UnUsedEpinReportService from "./UnUsedEpinReportService.js";
import ReactPaginate from "react-paginate";

const UnUsedEpinReport = () => {
  const {
    getUnUsedEpinList,
    fetchUnUsedEpinList,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  } = UnUsedEpinReportService();

  useEffect(() => {
    fetchUnUsedEpinList();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(getUnUsedEpinList.length / itemsPerPage));
    setCurrentItems(getUnUsedEpinList.slice(0, itemsPerPage));
  }, [getUnUsedEpinList]);

  return (
    <>
      <div className="report mt-top">
        <div className="card card-style">
          <div className="content mb-0">
            <h1 className="mb-3">Unused E-Pin Report</h1>
            <div className="divider mb-4"></div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead style={{ background: "rgb(220, 155, 6)" }}>
                  <tr>
                    <th>#</th>
                    <th>Epin</th>
                    <th>Cost</th>
                    <th>isActive</th>
                    <th>AssignedTo</th>
                    <th>Date Time</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="6" className="text-center">
                        <div
                          className="spinner-border text-black"
                          role="status"
                        ></div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((e, i) => (
                        <tr key={e.id}>
                          <td>{currentPage * itemsPerPage + i + 1}</td>
                          <td>{e?.epin}</td>
                          <td>{e?.cost}</td>
                          <td>{e?.isActive ? "True" : "False"}</td>
                          <td>{e?.assignedTo}</td>
                          <td>{e?.entryDate}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
            <ReactPaginate
              previousLabel="< Previous"
              nextLabel="Next >"
              breakLabel="..."
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default UnUsedEpinReport;
