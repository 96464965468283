import ApiCalling from "../../../../Services/ApiCalling";
import { toast } from "react-toastify";
import { useState } from "react";
import PayLoadObject from "../../../../utils/payloadObj";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const HelpPaymentService = () => {
  const navigate=useNavigate();
  const userID = sessionStorage.getItem("userID");
  const [input, setInput] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const location = useLocation();
  const { helpAmount } = location.state || {};
  const { Level } = location.state || {};
  const { USERID } = location.state || {};
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errors, setErrors] = useState({});

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const fetchHelpPayment = async () => {
    try {
      const obj = {
        appSession: PayLoadObject(),
        Request: {
          AssignedTo: USERID,
        },
      };
      const res = await ApiCalling.request(
        "POST",
        "App/GetBankDeatilsforEpin",
        obj,
        null
      );
      if (res?.statuscode === 1) {
        setInput(res?.data);
      } else {
        toast.error(res?.msg, { toastId: "Bank Detail Error" });
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
      // toast.error(
      //   "An error occurred while fetching the profile data. Please try again."
      // );
    }
  };

  const handleFileChange = (e) => {
    setInput({
      ...input,
      file: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setLoadingBtn(true);
    const data = new FormData();
    try {
      data.append("file", input["file"]);
      data.append(
        "UserFundRequest",
        JSON.stringify({
          ...PayLoadObject(),
          Level: Level,
          UTR: input?.UTR,
          PaymentMode: selectedValue,
          Amount: helpAmount,
          ToUserID: USERID,
        })
      );
      let res = await ApiCalling.formDataRequest(
        "POST",
        "App/FundRequestEpin",
        data
      );
      if (res?.statuscode === 1) {
        toast.success(res?.msg, { toastId: "Fund Request success" });
        setInput({ UTR: "" });
        navigate(`/giveHelp?level=${Level}`);
      } else {
        toast.error(res?.msg, { toastId: "Fund Request Error" });
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
    setLoadingBtn(false);
  }

  const validateInput = () => {
    const newErrors = {};
    if (!input?.UTR) {
      newErrors.UTR = "Please enter UTR.";
    }
    if (!input?.file) {
      newErrors.file = "Please upload file.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return {
    fetchHelpPayment,
    input,
    handleChange,
    handleSubmit,
    selectedValue,
    handleDropdownChange,
    handleFileChange,
    loadingBtn,
    errors
  };
};

export default HelpPaymentService;
