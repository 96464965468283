import { toast } from "react-toastify";
import ApiCalling from "../../../../Services/ApiCalling";
import { useState } from "react";
import PayLoadObject from "../../../../utils/payloadObj";

const ViewProfileService = () => {
  const [profileData, setProfileData] = useState(null);
  const payload = PayLoadObject();
  const userId = payload.userID;
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [bankList, setBankList] = useState([]);

  const fetchData = async () => {
    try {
      const data = await ApiCalling.request('POST', 'App/GetProfile', {
        ...PayLoadObject(),
        isUPI: false,
        uid: 0,
      }, null);

      if (data?.statuscode === 1) {
        setProfileData(data?.userInfo);
        setInput(data?.userInfo);

      } else {
        toast.error(data?.msg,{toastId:"Something wrong"});
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
     // toast.error('An error occurred while fetching the profile data. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const fetchBankList = async () => {
    try {
      const data = await ApiCalling.request('POST', 'App/GetBankList', {
        paymentID: 0,
        ID: 0,
        ...PayLoadObject(),
        uid: 0,
      }, null);

      if (data?.statuscode === 1) {
        setBankList(data?.bankMasters);
      } else {
        toast.error(data?.msg,{toastId:"Error occured"});
      }
    } catch (error) {
      console.error('Error fetching bank list:', error);
    //  toast.error('An error occurred while fetching the bank list. Please try again.');
    }
  };

  const updateProfile = async () => {
    const bodyObject = {
      editUser: {
        ...input,
        userID: parseInt(userId)
      },
      ...PayLoadObject(),
      isUPI: false,
      uid: 0,

    };
    try {
      const isFormValid = validateForm(bodyObject.editUser);
      setErrors(isFormValid);
      if (Object.keys(isFormValid).length > 0) {
        toast.error('Please fix the validation errors before updating.');
        return;
      }
      const data = await ApiCalling.request('POST', 'App/UpdateProfile', bodyObject, null);
      if (data?.statuscode === 1) {
        toast.success(data?.msg,{toastId:"Successfully!"});
        fetchData();
      } else {
        toast.error(data?.msg,{toastId:"Some Error occured"});
      }
    } catch (error) {
      console.error('Error updating profile data:', error);
      //toast.error('An error occurred while updating the profile data. Please try again.');
    }
  };

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "alternateMobile":
        const mobileRegex = /^[0-9]{10}$/;
        if (!value) {
          error = "Mobile number is required";
        } else if (!mobileRegex.test(value)) {
          error = "Invalid mobile number format";
        }
        break;
      case "pincode":
        if (!value) {
          error = "Pincode is required";
        }
        break;
      case "accountNumber":
        const accountNumberRegex = /^[a-zA-Z0-9]{6,25}$/;
        if (!value) {
          error = "Account number is required";
        } else if (!accountNumberRegex.test(value)) {
          error = "Account number must be between 6 and 25 alphanumeric characters.";
        }
        break;
      case "ifsc":
        const ifscRegex = /^[A-Za-z0-9]{11}$/;
        if (!value) {
          error = "IFSC code is required";
        } else if (!ifscRegex.test(value)) {
          error = "IFSC code must be exactly 11 alphanumeric characters.";
        }
        break;

      case "pincode":
        const pincodeRegex = /^[0-9]{1,6}$/;
        if (!value) {
          error = "Pincode is required";
        } else if (!pincodeRegex.test(value)) {
          error = "Pincode must be numeric and should not exceed 6 digits";
        }
        break;
      default:
        break;
    }

    return error;
  };

  const validateForm = (input) => {
    const errors = {};
    Object.keys(input).forEach((name) => {
      const error = validateInput(name, input[name]);
      if (error) {
        errors[name] = error;
      }
    });
    return errors;
  }
  return {
    fetchData,
    profileData,
    handleChange,
    input,
    updateProfile,
    userId,
    errors,
    bankList,
    fetchBankList,
  }
}

export default ViewProfileService;