const DATACONSTANT = {
    KYC_STATUSTYPE: {
        1: { text: "NOT APPLIED", color: "orange" },
        2: { text: "APPLIED", color: "blue" },
        3: { text: "COMPLETED", color: "green" },
        4: { text: "REKYC", color: "darkorange" },
        5: { text: "REJECTED", color: "red" },
      },

     

      GIVEHELP_STATUSTYPE: {
        0: { text: 'Not Paid', color: 'red' },
        1: { text: 'Pending', color: 'darkorange' },
        2: { text: 'Approved', color: 'green' },
        3: { text: 'Rejected', color: 'darkred' },
      }

  };
  
  export default DATACONSTANT;