import React from "react";
import Select from "react-select";
import { Link, useSearchParams } from "react-router-dom";
import SignUpService from "./SignUpService";
import { useEffect } from "react";
const SignUp = () => {
  const {
    input,
    handleChange,
    handleSubmit,
    errors,
    fetchBankList,
    bankData,
    showModal,
    handleSubmitModal,
    passwords,
    userIds,
    userNames,
    areaData,
    fetchAreaByPin,
    setAreaData,
  } = SignUpService();
  const [searchParams] = useSearchParams();
  const apibaseURL = process.env.REACT_APP_APIBASE_URL;
  const date = new Date().getTime();

  useEffect(() => {
    const pincode = input.pincode;
    if (pincode) {
      const timeoutId = setTimeout(async () => {
        await fetchAreaByPin(pincode);
      }, 1000);
      return () => clearTimeout(timeoutId);
    } else {
      setAreaData([]);
    }
  }, [input.pincode]);

  useEffect(() => {
    fetchBankList();
    const userID = searchParams.get("userid");
    if (userID) {
      handleChange({ target: { name: "ReferalIDStr", value: userID } });
    }
  }, [searchParams]);

  const handleSelectChange = (e) => {
    input.BankName = e.target.value;
  };

  const bankOptions = bankData?.map((bank) => ({
    value: bank.bankName,
    label: bank.bankName,
  }));

  return (
    <div className="loginContainer">
      <div class="container mt-5 py-5">
        <div class="card card-outline card-primary bg-light">
          <div class="overlay-wrapper">
            <div class="overlay" style={{ display: "none" }} id="loading">
              <i class="fas fa-3x fa-sync-alt fa-spin"></i>
            </div>
            <div
              class="card-header text-center "
              style={{ background: "#dc9b06" }}
            >
              <h5>Register a new membership</h5>
            </div>
            <div class="card-body">
              <h5 class="text-success">User Information:</h5>
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label>
                    Referral Id<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    id="ReferalIDStr"
                    name="ReferalIDStr"
                    placeholder="Enter Referral Id"
                    required="required"
                    type="text"
                    value={input?.ReferalIDStr}
                    onChange={handleChange}
                  />
                  {errors.ReferalIDStr && (
                    <small className="text-danger">{errors.ReferalIDStr}</small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>Referral Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Referralname"
                    placeholder="Referral Name"
                    name="Referralname"
                    readonly=""
                    value={input?.Referralname}
                  />
                </div>

                <div class="mb-3 col-md-6">
                  <label>
                    Name<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    required="required"
                    type="text"
                    value={input?.name}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <small className="text-danger">{errors.name}</small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    id="emailid"
                    name="emailid"
                    placeholder="Enter Email Id"
                    required="required"
                    type="text"
                    value={input?.emailid}
                    onChange={handleChange}
                  />
                  {errors.emailid && (
                    <small className="text-danger">{errors.emailid}</small>
                  )}
                </div>

                <div class="mb-3 col-md-6">
                  <label>
                    Mobile No<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control only_numbers"
                    id="mobileNo"
                    maxlength="10"
                    name="mobileNo"
                    placeholder="Enter Mobile No "
                    required="required"
                    type="text"
                    value={input?.mobileNo}
                    onChange={handleChange}
                  />
                  {errors.mobileNo && (
                    <small className="text-danger">{errors.mobileNo}</small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>
                    PinCode<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    id="pincode"
                    name="pincode"
                    placeholder="Enter Pincode"
                    required="required"
                    type="text"
                    value={input?.pincode}
                    onChange={handleChange}
                  />
                  {errors.pincode && (
                    <small className="text-danger">{errors.pincode}</small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>
                    State<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    id="statename"
                    name="statename"
                    placeholder="Enter State Name"
                    required="required"
                    type="text"
                    value={areaData?.[0]?.statename || ""}
                    readOnly
                  />
                  {errors.statename && (
                    <small className="text-danger">{errors.statename}</small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>
                    City<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    id="city"
                    name="city"
                    placeholder="Enter city"
                    required="required"
                    type="text"
                    value={areaData?.[0]?.city || ""}
                    readOnly
                  />
                  {errors.city && (
                    <small className="text-danger">{errors.city}</small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>
                    Area<span className="text-danger">*</span>
                  </label>
                  <select
                    class="form-control"
                    id="id"
                    name="areaId"
                    required="required"
                   value={input?.areaId || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select Area</option>
                    {areaData?.map((i)  => (
                     <option key={i.id} value={i.id}>
                        {i.area}
                      </option>
                    ))}
                  </select>
                  {errors.id && (
                    <small className="text-danger">{errors.id}</small>
                  )}
                </div>

                <div class="mb-3 col-md-6">
                  <label>
                    Password<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    id="Password"
                    name="Password"
                    placeholder="Enter Password"
                    required="required"
                    type="text"
                    value={input?.Password}
                    onChange={handleChange}
                  />
                  {errors.Password && (
                    <small className="text-danger">{errors.Password}</small>
                  )}
                </div>
              </div>
              <h5 class="text-success">Account Information:</h5>
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label>
                    Bank Name<span className="text-danger">*</span>
                  </label>
                 <Select
                    id="bankName"
                    name="bankName"
                    value={bankOptions.find(
                      (option) => option.value === input?.bankName
                    )}
                    onChange={(selectedOption) =>
                      handleSelectChange({
                        target: {
                          name: "bankName",
                          value: selectedOption.value,
                        },
                      })
                    }
                    options={bankOptions}
                    placeholder="Select a bank"
                    isSearchable
                  />
                  {errors.BankName && (
                    <small className="text-danger">{errors.BankName}</small>
                  )}
                </div>

                <div class="mb-3 col-md-6">
                  <label>
                    Account Holder Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="AccountHolder"
                    placeholder="Enter Account Holder Name"
                    name="AccountHolder"
                    required=""
                    value={input?.AccountHolder}
                    onChange={handleChange}
                  />
                  {errors.AccountHolder && (
                    <small className="text-danger">
                      {errors.AccountHolder}
                    </small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>
                    Account No<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="AccountNo"
                    placeholder="Enter Account No"
                    name="AccountNo"
                    required=""
                    value={input?.AccountNo}
                    onChange={handleChange}
                  />
                  {errors.AccountNo && (
                    <small className="text-danger">{errors.AccountNo}</small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>
                    IFSC Code<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="IFSC"
                    placeholder="Enter IFSC Code"
                    name="IFSC"
                    required=""
                    value={input?.IFSC}
                    onChange={handleChange}
                  />
                  {errors.IFSC && (
                    <small className="text-danger">{errors.IFSC}</small>
                  )}
                </div>
                <div class="mb-3 col-md-6">
                  <label>
                    UPI Id<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="UPIID"
                    placeholder=" Enter UPI ID"
                    name="UPIID"
                    required=""
                    value={input?.UPIID}
                    onChange={handleChange}
                  />
                  {errors.UPIID && (
                    <small className="text-danger">{errors.UPIID}</small>
                  )}
                </div>
                <div class="col-md-6">
                  <label>
                    E-Pin No<span className="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    id="EPIN"
                    name="EPIN"
                    placeholder="Enter E-Pin No"
                    required=""
                    value={input?.EPIN}
                    onChange={handleChange}
                  />
                  {errors.EPIN && (
                    <small className="text-danger">{errors.EPIN}</small>
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right">
                  <button
                    type="button"
                    id="btnSubmit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    SignUp Now!
                  </button>
                </div>
                <div class="col-md-12 text-left">
                  <Link to="/login">I already have a membership</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade-Modalclass ${showModal ? "show" : ""}`}
        id="ModalUserRegistration"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" style={{ height: "auto", width: "350px" }}>
            <div class="modal-body pt-md-0">
              <div class=" text-center mt-3 cus-regis">
                <h2>Registration Successful</h2>
              </div>
              <h4 class="mb-2 mt-4 text-center cus-created0">
                Welcome,&nbsp;<span>{userNames}</span>&nbsp; your account has
                been created successfully.
              </h4>
              <p className="cus-credential-0">
                Your Login Credential are as follows and also we have sent you
                an email and message containing your login details. Please keep
                the email safe.
              </p>
              <hr />
              <table width="100%" id="detpopup">
                <tr>
                  <td width="30%">
                    Your Name:&nbsp;<span>{userNames}</span>
                  </td>
                  <td width="70%">
                    <span id="spnUserName"></span>
                  </td>
                </tr>
                <tr>
                  <td width="30%">
                    User Id:&nbsp;<span>{userIds}</span>
                  </td>
                  <td width="70%">
                    <span id="spnUserId"></span>
                  </td>
                </tr>
                <tr>
                  <td width="30%">
                    Password:&nbsp;<span>{passwords}</span>
                  </td>
                  <td width="70%">
                    <span id="spnPassword"></span>
                  </td>
                </tr>
              </table>

              <div>
                <p>
                  <img
                    src={`${apibaseURL}Image/Website/1/logo.png?${date}}`}
                    alt="Logo"
                    className="mx-auto  over-card mt-3"
                    width="200"
                    height={40}
                  />
                </p>
                <button
                  type="button"
                  className="close "
                  style={{ color: "red" }}
                  onClick={handleSubmitModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="cus-close">
                    &times;
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
