import React from 'react'
import { Link } from 'react-router-dom'
import LoginService from './LoginService'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faUser } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const { handleChange, input, handleClick, errors, showNewPassword, setShowNewPassword, disableButton } = LoginService();

    return (
        <div className='loginContainer'> <div class="login-box">

            <form action="/Home/UserLogin?Length=4" data-ajax="true" data-ajax-failure="fnFailure" data-ajax-loading="#loading" data-ajax-method="POST" data-ajax-success="fnSuccess" enctype="multipart/form-data" id="form0" method="post">
                <div class="card">
                    <div class="overlay-wrapper">
                        <div class="overlay" style={{ "display": "none" }} id="loading"><i class="fas fa-3x fa-sync-alt fa-spin"></i></div>
                        <div class="card-body login-card-body">
                            {/* <div class="login-logo">
                                <a href="/">
                                    <img src="/images/logo.png" alt="" class="img-responsive" style={{ height: "80px" }} />

                                </a>
                            </div> */}
                            <div className='card-header' style={{ height: "40px", background: "#dc9b06" }}>
                                <p class="login-box-msg" style={{ color: "black" }}>Sign in to start your session</p>
                            </div>

                            <div class="input-group mt-3">
                                <input
                                    class="form-control"
                                    type="text"
                                    id="userID"
                                    name="userID"
                                    placeholder="Enter the user Id"
                                    value={input?.userID}
                                    onChange={handleChange}
                                />
                                <div class="input-group-append" >
                                    <div class="input-group-text cus-input-text" style={{ backgroundColor: "rgb(220, 155, 6)" }}>
                                        <span>
                                            <FontAwesomeIcon icon={faUser} />
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <span>   {errors.userID && (
                                <div className="help-block text-danger">
                                    {errors.userID}
                                </div>
                            )}</span>
                            <div class="input-group mt-3">
                                <input autocomplete="off"
                                    class="form-control"
                                    type={showNewPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    placeholder="Enter the password"
                                    value={input.password}
                                    onChange={handleChange}
                                />
                                <div class="input-group-append cus-ps-10" style={{ background: "rgb(220, 155, 6)" }}>
                                    <button
                                        className="btn cus-btn-group-append"
                                        type="button"
                                        onClick={() =>
                                            setShowNewPassword(!showNewPassword)
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={showNewPassword ? faEyeSlash : faEye}
                                        />
                                    </button>
                                </div>
                            </div>
                            <span>  {errors.password && (
                                <div className="help-block text-danger">
                                    {errors.password}
                                </div>
                            )}</span>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-block"
                                        onClick={handleClick}
                                        disabled={disableButton}
                                    >
                                        {disableButton ? 'Logging in...' : 'Login'}
                                    </button>
                                </div>
                            </div>
                            <div class="">
                                <div class="col-md-12 text-center mt-2">
                                    <Link to="/signup">
                                        Not Registered?Join Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </div>

    )
}

export default Login