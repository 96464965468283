import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [avtiveTab, setActiveTab] = useState("dashboard");
  const onTabChange = (val) => {
    setActiveTab(val);
  };

  return (
    <>
      <div id="footer-bar" class="footer-bar-1 nav-item">
        <Link
          className={`nav-link ${
            avtiveTab === "dashboard" ? "active-nav" : ""
          }`}
          onClick={(e) => onTabChange("dashboard")}
          id="home-tab"
          to={"/dashboard"}
        >
          <i class="fa fa-home"></i>
          <span>Home</span>
        </Link>
        <Link
          className={`nav-link ${avtiveTab === "account" ? "active-nav" : ""}`}
          onClick={(e) => onTabChange("account")}
          id="profile-tab"
          to={"/account"}
        >
          <i class="fa fa-user"></i>
          <span>Account</span>
        </Link>
        <Link
          className={`nav-link ${avtiveTab === "give" ? "active-nav" : ""}`}
          onClick={(e) => onTabChange("give")}
          id="profile-tab"
          to={"/levelWiseLinkChart"}
        >
          <i class="fa fa-donate"></i>
          <span>Give/Take Help</span>
        </Link>
        <Link
          className={`nav-link ${avtiveTab === "network" ? "active-nav" : ""}`}
          onClick={(e) => onTabChange("network")}
          id="contact-tab"
          role="tab"
          to={"/network"}
        >
          <i class="fa fa-users"></i>
          <span>Network</span>
        </Link>
        {/* <Link
          className={`nav-link ${avtiveTab === "setting" ? "active-nav" : ""}`}
          onClick={(e) => onTabChange("setting")}
          id="setting-tab"
        >
          <i class="fa fa-cog"></i>
          <span>Setting</span>
        </Link> */}
      </div>
    </>
    // <div className="bottom_tab">
    //   <ul className="nav nav-tabs nav-justified" role="tablist">
    //     <div className="slider"></div>
    //     <li className="nav-item">
    //       <Link
    //         className={`nav-link ${avtiveTab === "dashboard" ? "active" : ""}`}
    //          onClick={(e) => onTabChange("dashboard")}
    //         id="home-tab"
    //          to={"/dashboard"}
    //         role="tab"
    //         aria-controls="dashboard"
    //         aria-selected="true"
    //       >
    //         <i className="fas fa-home"></i><span className="footer-tag">Home</span>
    //       </Link>
    //     </li>
    //     <li className="nav-item">
    //       <Link
    //         className={`nav-link ${avtiveTab === "account" ? "active" : ""}`}
    //         onClick={(e) => onTabChange("account")}
    //         id="profile-tab"
    //         to={"/account"}
    //         role="tab"
    //         aria-controls="account"
    //         aria-selected="false"
    //       >
    //         <i className="fas fa-id-card"></i><span className="footer-tag">Account</span>
    //       </Link>
    //     </li>
    //     <li className="nav-item">
    //       <Link
    //         className={`nav-link ${avtiveTab === "give" ? "active" : ""}`}
    //         onClick={(e) => onTabChange("give")}
    //         id="profile-tab"
    //         to={"/levelWiseLinkChart"}
    //         role="tab"
    //         aria-controls="help"
    //         aria-selected="false"
    //       >
    //         <i className="fa fa-donate"></i><span className="footer-tag">Give/Take Help</span>
    //       </Link>
    //     </li>
    //     <li className="nav-item">

    //       <Link
    //         className={`nav-link ${avtiveTab === "network" ? "active" : ""}`}
    //         onClick={(e) => onTabChange("network")}
    //         id="contact-tab"
    //         role="tab"
    //          to={'/network'}
    //         aria-controls="contact"
    //         aria-selected="false"
    //       >
    //         <i className="fas fa-map-signs"></i><span className="footer-tag">Network</span>
    //       </Link>
    //     </li>
    //     {/* <li className="nav-item">
    //       <Link
    //         className={`nav-link ${avtiveTab === "setting" ? "active" : ""}`}
    //          onClick={(e) => onTabChange("setting")}
    //         id="setting-tab"
    //         role="tab"
    //         aria-controls="setting"
    //         aria-selected="false"
    //       >
    //         <i className="fas fa-cogs"></i><span className="footer-tag">Settings</span>
    //       </Link>
    //     </li> */}
    //   </ul>
    // </div>
  );
};

export default Footer;
