import React from "react";
import BodyService from "./BodyService";
import { useEffect } from "react";

const Body = () => {
  const apibaseURL = process.env.REACT_APP_APIBASE_URL;
  const baseurl = process.env.REACT_APP_BASEURL;
  const date = new Date().getTime();
  const userID = sessionStorage.getItem("userID");
  const prefix = sessionStorage.getItem("prefix");

  const { fetchHelpDasboardData, fetchHelpingPlanDashboard } = BodyService();

  useEffect(() => {
    fetchHelpingPlanDashboard();
  }, []);

  const referralLink = `${baseurl}signup?userid=${prefix}${userID}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      alert("Referral link copied to clipboard!");
    } catch (err) {
      console.error("Could not copy text: ", err);
    }
  };

  return (
    <div class="main-widget">
      <div class="container">
        <div class="tile" id="tile-1">
          <div class="tab-content">
            <div class="tab-pane fade show active">
              <div class="row">
                <div class="col-md-12">
                  <div class="slider-section">
                    <div
                      id="magicCarousel"
                      class="carousel slide mt-5 mb-4"
                      data-ride="carousel"
                    >
                      <ol class="carousel-indicators">
                        <li
                          data-target="#magicCarousel"
                          data-slide-to="0"
                          class="active"
                        ></li>
                        <li data-target="#magicCarousel" data-slide-to="1"></li>
                        <li data-target="#magicCarousel" data-slide-to="2"></li>
                        <li data-target="#magicCarousel" data-slide-to="3"></li>
                      </ol>

                      <div class="carousel-inner" role="listbox">
                        <div class="carousel-item active">
                          <img
                            src="assets/img/banner_1.png"
                            class="d-block w-100"
                          />
                          {/* <div class="carousel-caption">
                                                        <h3>Slide 01</h3>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At placeat dolore obcaecati perspiciatis neque in, iusto, quia aut molestias minus ipsam modi dolorem odit eos.</p>
                                                    </div> */}
                        </div>

                        <div class="carousel-item">
                          <img
                            src="assets/img/banner_2.png"
                            class="d-block w-100"
                          />
                          {/* <div class="carousel-caption">
                                                        <h3>Slide 02</h3>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia vel in placeat corrupti. Velit quidem eos, accusantium vitae nostrum reiciendis ratione libero? Aperiam, recusandae culpa.</p>
                                                    </div> */}
                        </div>

                        <div class="carousel-item">
                          <img
                            src="assets/img/banner_3.png"
                            class="d-block w-100"
                          />
                          {/* <div class="carousel-caption">
                                                        <h3>Slide 03</h3>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam, ut beatae porro ullam quidem officia dignissimos facilis? Blanditiis nesciunt labore pariatur quaerat exercitationem quibusdam modi!</p>
                                                    </div> */}
                        </div>

                        <div class="carousel-item">
                          <img
                            src="assets/img/banner_2.png"
                            class="d-block w-100"
                          />
                          {/* <div class="carousel-caption">
                                                        <h3>Slide 04</h3>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis soluta accusamus sequi eos consectetur! Animi minima modi ad at similique velit quibusdam veritatis sequi placeat?</p>
                                                    </div> */}
                        </div>

                        <a
                          href="#magicCarousel"
                          class="carousel-control-prev"
                          role="button"
                          data-slide="prev"
                        >
                          <span class="carousel-control-prev-icon"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          href="#magicCarousel"
                          class="carousel-control-next"
                          role="button"
                          data-slide="next"
                        >
                          <span class="carousel-control-next-icon"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="widget mb-3 pb-3">
                <div class="row">
                  <div class=" col-md-6 pr-0">
                    <div class="card widget-flat mb-3 bg-info-1">
                      <div class="card-body">
                        <div class="float-end">
                          <i class="fa fa-calendar widget-icon fa-3x text-grey"></i>
                        </div>
                        <h5 class="fw-normal mt-0 text-white" title="Revenue">
                          Total Give Help
                        </h5>
                        <h3 class="mt-3 mb-3 text-white">
                          <span style={{ fontSize: "20px" }}>&#8377;</span>
                          &nbsp;{fetchHelpDasboardData.totalGiveHelp}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class=" col-md-6">
                    <div class="card widget-flat mb-3 bg-success-1">
                      <div class="card-body">
                        <div class="float-end">
                          <i class="fa fa-calendar widget-icon fa-3x text-grey"></i>
                        </div>
                        <h5 class="text-white fw-normal mt-0" title="Revenue">
                          Total Recieved Help
                        </h5>
                        <h3 class="mt-3 mb-3 text-white">
                          <span style={{ fontSize: "20px" }}>&#8377;</span>
                          &nbsp;{fetchHelpDasboardData?.totalReciveHelp}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class=" col-md-6 pr-0">
                    <div class="card widget-flat mb-3 bg-danger-1">
                      <div class="card-body">
                        <div class="float-end">
                          <i class="fa fa-calendar widget-icon fa-3x text-grey"></i>
                        </div>
                        <h5 class="text-white fw-normal mt-0" title="Revenue">
                          Total Pending Give Help
                        </h5>
                        <h3 class="mt-3 mb-3 text-white">
                          <span style={{ fontSize: "20px" }}>&#8377;</span>
                          &nbsp;{fetchHelpDasboardData?.totalPendingGiveHelp}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class=" col-md-6">
                    <div class="card widget-flat mb-3 bg-warning">
                      <div class="card-body">
                        <div class="float-end">
                          <i class="fa fa-calendar widget-icon fa-3x text-grey"></i>
                        </div>
                        <h5 class="text-white fw-normal mt-0" title="Revenue">
                          Total Pending Recieved Help
                        </h5>
                        <h3 class="mt-3 mb-3 text-white">
                          <span style={{ fontSize: "20px" }}>&#8377;</span>
                          &nbsp;{fetchHelpDasboardData?.totalPendingReciveHelp}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class=" col-md-6 pr-0">
                    <div class="card widget-flat bg-success-1">
                      <div class="card-body">
                        <div class="float-end">
                          <i class="fa fa-calendar widget-icon fa-3x text-grey"></i>
                        </div>
                        <h5 class="text-white fw-normal mt-0" title="Revenue">
                          Direct Income
                        </h5>
                        <h3 class="mt-3 mb-3 text-white">
                          <span style={{ fontSize: "20px" }}>&#8377;</span>
                          &nbsp;{fetchHelpDasboardData?.directIncome}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class=" col-md-6">
                    <div class="card widget-flat bg-info-1">
                      <div class="card-body">
                        <div class="float-end">
                          <i class="fa fa-calendar widget-icon fa-3x text-grey"></i>
                        </div>
                        <h5 class="text-white fw-normal mt-0" title="Revenue">
                          Total Team
                        </h5>
                        <h3 class="mt-3 mb-3 text-white">
                          {fetchHelpDasboardData?.totalTeam}
                        </h3>
                        {/* <p class="mb-0 text-muted">
                                                    <span class="badge badge-white mr-1">
                                                        <i class="mdi mdi-arrow-down-bold"></i>7.00%</span>
                                                    <span class="text-nowrap text-white">Since last month</span>
                                                </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="refferal_link text-center mb-3 card p-5">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Share Referral Link</h3>
                    <p>{referralLink}</p>
                    <button
                      id="user_share"
                      className="btn btn-danger  text-uppercase font-900 bg-highlight rounded-sm shadow-xl"
                      onClick={handleCopyLink}
                    >
                      Copy Link
                    </button>
                  </div>
                </div>
              </div>

              <div class="text-center mb-5 card p-5">
                <div class="row">
                  <div class="col-md-12">
                    <a href="">
                      <img
                        src={`${apibaseURL}Image/Website/1/logo.png?${date}}`}
                        className="logo-img"
                        alt="Logo"
                      />
                    </a>

                    <div class="footer_medialink">
                      <div class="template-demo mt-4 pb-3">
                        <button
                          type="button"
                          class="btn btn-social-icon btn-facebook btn-rounded"
                        >
                          <i class="fab fa-facebook"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-social-icon btn-youtube btn-rounded"
                        >
                          <i class="fab fa-youtube"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-social-icon btn-twitter btn-rounded"
                        >
                          <i class="fab fa-twitter"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-social-icon btn-dribbble btn-rounded"
                        >
                          <i class="fab fa-dribbble"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-social-icon btn-linkedin btn-rounded"
                        >
                          <i class="fab fa-linkedin"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-social-icon btn-instagram btn-rounded"
                        >
                          <i class="fab fa-instagram"></i>
                        </button>
                      </div>

                      <hr />

                      <p class="footer-copyright">
                        Copyright © Enabled{" "}
                        <span id="copyright-year">2024</span>. All Rights
                        Reserved.
                      </p>

                      <hr />

                      <p class="footer-links mb-0">
                        <a href="#" class="color-highlight">
                          Privacy Policy
                        </a>{" "}
                        |{" "}
                        <a href="#" class="color-highlight">
                          Terms and Conditions
                        </a>{" "}
                        |{" "}
                        <a href="#" class="back-to-top color-highlight">
                          {" "}
                          Back to Top
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
