import React, { useEffect } from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter";
import SponserInfoService from "./SponserInfoService";
import ReactPaginate from "react-paginate";

const SponserInfo = () => {
  const {
    fetchSponserInfo,
    getSponserList,
    levelNo,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  } = SponserInfoService();

  useEffect(() => {
    fetchSponserInfo();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(getSponserList.length / itemsPerPage));
    setCurrentItems(getSponserList.slice(0, itemsPerPage));
  }, [getSponserList]);

  return (
    <>
      <>
        <div className="mt-top">
          <div className="card card-style">
            <div className="content mb-0">
              <h1 className="mb-3">Level {levelNo} Team</h1>
              <div className="table-responsive e-pinreport">
                <table className="table table-bordered">
                  <thead style={{ background: "rgb(220, 155, 6)" }}>
                    <tr>
                      <th>#</th>
                      <th>User Id</th>
                      <th>Name</th>
                      <th>Sponsor Id</th>
                      <th>Sponsor Name</th>
                      <th>Upline Id</th>
                      <th>Upline Name</th>
                      <th>Reg.Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="9" className="text-center">
                          <div
                            className="spinner-border text-black"
                            role="status"
                          ></div>
                        </td>
                      </tr>
                    ) : currentItems?.length > 0 ? (
                      currentItems.map((e, i) => (
                        <tr key={i}>
                          <td>{currentPage * itemsPerPage + i + 1}</td>
                          <td>
                            {e?.uPrefix}
                            {e?.userId}
                          </td>
                          <td>{e?.username}</td>
                          <td>
                            {e?.sPrefix}
                            {e?.sponsoredId}
                          </td>
                          <td>{e?.sponserName}</td>
                          <td>
                            {" "}
                            {e?.sPrefix}
                            {e?.parentUserId}
                          </td>
                          <td>{e?.parentName}</td>
                          <td>{e?.registerDate}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <ReactPaginate
                previousLabel="< Previous"
                nextLabel="Next >"
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
              />
            </div>
          </div>
        </div>
        <BottomFooter />
      </>
    </>
  );
};

export default SponserInfo;
