import { useState } from "react";
import ApiCalling from "../../../../Services/ApiCalling";
import PayLoadObject from "../../../../utils/payloadObj";
import { useLocation } from "react-router-dom";

const SponserInfoService = () => {
  const payload = PayLoadObject();
  const userId = payload.userID;
  const [getSponserList, setGetSponserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    const offset = selected * itemsPerPage;
    setCurrentItems(getSponserList.slice(offset, offset + itemsPerPage));
    setCurrentPage(selected);
  };

  function formatDate(date) {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
  }

  const currentDate = new Date();

  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(currentDate.getFullYear() - 2);

  const toDate = formatDate(currentDate);
  const fromDate = formatDate(twoYearsAgo);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const levelNo = queryParams.get("level");

  const bodyObject = {
    appSession: {
      ...payload,
      ID: 0,
      uid: 0,
    },
    request: {
      FromDate: fromDate,
      IsRecent: true,
      LevelNo: levelNo,
      SearchType: "1",
      Status: "All",
      ToDate: toDate,
      searchUserId: userId,
    },
  };

  const fetchSponserInfo = async () => {
    const res = await ApiCalling.request(
      "POST",
      "Dashboard/SponserList",
      bodyObject,
      null
    );
    if (res?.statuscode === 1) {
      setGetSponserList(res?.data);
    }
    setLoading(false);
  };

  return {
    fetchSponserInfo,
    getSponserList,
    levelNo,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  };
};

export default SponserInfoService;
