import { useState } from "react";
import ApiCalling from "../../../../Services/ApiCalling";
import { toast } from "react-toastify";
import PayLoadObject from "../../../../utils/payloadObj";

const ChangePinService = () => {
  const [inputPin, setInputPin] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [error, setErrors] = useState({});

  const handleChangePin = (e) => {
    const { name, value } = e.target;
    setInputPin({
      ...inputPin,
      [name]: value,
    });
  };

  const handleSubmitPin = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    if (inputPin.NewP !== inputPin.ConfirmP) {
      toast.error("New Password and Confirm Password do not match.", {
        toastId: "Password Mismatch",
      });
      return;
    }
    setLoadingBtn(true);
    const objectbody = {
      ...PayLoadObject(),
      ConfirmP: inputPin.ConfirmP,
      NewP: inputPin.NewP,
      isPin: false,
      oldP: inputPin.oldP,
    };
    try {
      const res = await ApiCalling.request(
        "POST",
        "App/ChangePinOrPassword",
        objectbody,
        null
      );

      if (res.statuscode === 1) {
        toast.success(res?.msg, { toastId: "Change Password Success" });
      } else {
        toast.error(res.msg, { toastId: "Change Password Error" });
      }
    } catch (error) {
      console.error("Error change password:", error);
      // toast.error(
      //   "An error occurred while updating the change password. Please try again."
      // );
    }
    setLoadingBtn(false);
  };

  const validateInput = () => {
    const newErrors = {};
    if (!inputPin?.oldP) {
      newErrors.oldP = "Please enter Old Password.";
    }
    if (!inputPin?.NewP) {
      newErrors.NewP = "Please enter New Password.";
    }
    if (!inputPin?.ConfirmP) {
      newErrors.ConfirmP = "Please enter Confirm Password";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return { inputPin, handleChangePin, handleSubmitPin, loadingBtn,error};
};

export default ChangePinService;
