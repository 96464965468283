import React from "react";
import BottomFooter from "../../Dashboard/BottomFooter/BottomFooter.jsx";
import { Link } from "react-router-dom";

const Network = () => {
  return (
    <>
      <div className="mt-top">
        <div class="card card-style">
          <div class="content my-0">
            <div class="list-group list-custom-small check-visited">
              <Link 
              to={'/refererralTeam'}
              >
                <i class="fa fa-user font-14 color-green-dark"></i>
                <span>Referral Team</span>
                <i class="fa fa-angle-right"></i>
              </Link>
              <Link to={'/levelWiseTeam'}>
                <i class="fa fa-wallet font-14 color-green-dark"></i>
                <span>Level Wise Team</span>
                <i class="fa fa-angle-right"></i>
              </Link>
              <Link 
             // to={'/topReceivers'}
              >
                <i class="fa fa-donate font-14 color-green-dark"></i>
                <span>Top Receivers</span>
                <i class="fa fa-angle-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <BottomFooter />
     
    </>
  );
};

export default Network;
