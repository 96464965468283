import React, { useEffect, useState } from "react";
import HelpPaymentService from "./HelpPaymentService";
import { useLocation } from "react-router-dom";

const HelpPayment = () => {
  const {
    fetchHelpPayment,
    input,
    handleChange,
    handleSubmit,
    selectedValue,
    handleDropdownChange,
    handleFileChange,
    loadingBtn,
    errors
  } = HelpPaymentService();

  const location = useLocation();
  const { helpAmount, USERID, Prefix } = location.state || {};
  const combineValue = `${Prefix}${USERID}`;

  useEffect(() => {
    fetchHelpPayment();
  }, []);

  return (
    <div className="main-section">
      <div className="card card-style">
        <div className="content mb-0" id="tab-group-1">
          <h1 className="mb-3">Help Payment</h1>
          <div className="col-md-12">
            <div className="form-group">
              <label>Payment Mode</label>
              <select
                className="form-control"
                value={selectedValue}
                onChange={handleDropdownChange}
              >
                <option value="">Select</option>
                <option value="UPI">UPI</option>
                <option value="BANK">BANK TRANSFER</option>
              </select>
            </div>
          </div>
          {selectedValue === "UPI" && (
            <div
              data-bs-parent="#tab-group-1"
              className="collapse show"
              id="tab-1"
            >
              <form
                action="/Smart/InsertPFCPayment?Length=5"
                data-ajax="true"
                data-ajax-failure="fnFailure"
                data-ajax-loading="#loading"
                data-ajax-method="POST"
                data-ajax-success="fnSuccess"
                encType="multipart/form-data"
                id="form0"
                method="post"
              >
                <div className="row">
                  {/* <div className="col-md-6" id="changeTakerUserId">
                    <div className="form-group">
                      <label>Change Taker User Id</label>
                      <input
                        type="text"
                        name="userID"
                        id="userID"
                        className="form-control"
                        value={USERID}
                        readOnly
                      />
                    </div>
                  </div> */}

                  <div className="col-md-6" id="TakerUserId">
                    <div className="form-group">
                      <label> Taker User Id</label>
                      <input
                        type="text"
                        name="userID"
                        id="userID"
                        className="form-control"
                        value={combineValue}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6" id="takerName">
                    <div className="form-group">
                      <label>Taker Name</label>
                      <input
                        type="text"
                        name="userName"
                        id="userName"
                        className="form-control"
                        value={input?.userName}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mobile No.</label>
                      <input
                        type="text"
                        name="mobileNo"
                        id="mobileNo"
                        className="form-control"
                        value={input?.mobileNo}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Donate Amount*</label>
                      <input
                        type="text"
                        name="Amount"
                        id="Amount"
                        value={helpAmount}
                        className="form-control"
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>UPI</label>
                      <input
                        type="text"
                        name="upiid"
                        id="upiid"
                        className="form-control"
                        value={input?.upiid}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>UTR No*</label>
                      <input
                        type="text"
                        name="UTR"
                        id="UTR"
                        value={input?.UTR}
                        onChange={handleChange}
                        className="form-control"
                      />
                         {errors.UTR && (
                        <div className="help-block text-danger">
                          {errors.UTR}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Receipt</label>
                      <br />
                      <input
                        type="file"
                        name="file"
                        id="file"
                        onChange={handleFileChange}
                      />
                       {errors.file && (
                        <div className="help-block text-danger">
                          {errors.file}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-l rounded-sm btn-full btn-warning font-700 text-uppercase mb-2 mt-3"
                  name="submit"
                  onClick={handleSubmit}
                  disabled={loadingBtn}
                >
                  {loadingBtn ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          )}
          {selectedValue === "BANK" && (
            <div
              data-bs-parent="#tab-group-1"
              className="collapse show"
              id="tab-2"
            >
              <form
                action="/Smart/InsertHelpPayment?Length=5"
                data-ajax="true"
                data-ajax-failure="fnFailure"
                data-ajax-loading="#loading"
                data-ajax-method="POST"
                data-ajax-success="fnSuccess"
                encType="multipart/form-data"
                id="form1"
                method="post"
              >
                <div className="row">
                  {/* <div className="col-md-6" id="changeTakerUserId">
                    <div className="form-group">
                      <label>Change Taker User Id</label>
                      <input
                        type="text"
                        name="userID"
                        id="userID"
                        className="form-control"
                        value={USERID}
                        readOnly
                      />
                    </div>
                  </div> */}

                  <div className="col-md-6" id="TakerUserId">
                    <div className="form-group">
                      <label> Taker User Id</label>
                      <input
                        type="text"
                        name="userID"
                        id="userID"
                        className="form-control"
                        value={combineValue}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6" id="takerName">
                    <div className="form-group">
                      <label>Taker Name</label>
                      <input
                        type="text"
                        name="userName"
                        id="userName"
                        className="form-control"
                        value={input?.userName}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mobile No.</label>
                      <input
                        type="text"
                        name="mobileNo"
                        id="mobileNo"
                        className="form-control"
                        value={input?.mobileNo}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Bank Name</label>
                      <input
                        type="text"
                        name="bankName"
                        id="bankName"
                        className="form-control"
                        value={input?.bankName}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Account Holder Name</label>
                      <input
                        type="text"
                        name="accountHolder"
                        id="accountHolder"
                        className="form-control"
                        value={input?.accountHolder}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Account No.</label>
                      <input
                        type="text"
                        name="accountNumber"
                        id="accountNumber"
                        className="form-control"
                        value={input?.accountNumber}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>IFSC</label>
                      <input
                        type="text"
                        name="ifsc"
                        id="ifsc"
                        className="form-control"
                        value={input?.ifsc}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Donate Amount*</label>
                      <input
                        type="text"
                        name="Amount"
                        id="Amount"
                        className="form-control"
                        value={helpAmount}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>UTR No*</label>
                      <input
                        type="text"
                        name="UTR"
                        id="UTR"
                        value={input?.UTR}
                        onChange={handleChange}
                        className="form-control"
                      />
                         {errors.UTR && (
                        <div className="help-block text-danger">
                          {errors.UTR}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Receipt</label>
                      <br />
                      <input
                        type="file"
                        name="file"
                        id="file"
                        onChange={handleFileChange}
                      />
                       {errors.file && (
                        <div className="help-block text-danger">
                          {errors.file}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-l rounded-sm btn-full btn-warning font-700 text-uppercase mb-2 mt-3"
                  name="submit"
                  onClick={handleSubmit}
                  disabled={loadingBtn}
                >
                  {loadingBtn ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpPayment;
