import React, { useState, useEffect } from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter.jsx";
import EpinReEpinReportService from "./TransferEpinReportService.js";
import ReactPaginate from "react-paginate";

const TransferEpinReport = () => {
  const [epiReportState, setEpinReportState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    const offset = selected * itemsPerPage;
    setCurrentItems(epiReportState.slice(offset, offset + itemsPerPage));
    setCurrentPage(selected);
  };

  const getUsedData = async () => {
    const allEpinTransferReportData =
      await EpinReEpinReportService.getTransferEpinReport();

    if (allEpinTransferReportData.statuscode === 1) {
      setEpinReportState(allEpinTransferReportData.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsedData();
    return () => {
      setEpinReportState([]);
    };
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(epiReportState.length / itemsPerPage));
    setCurrentItems(epiReportState.slice(0, itemsPerPage));
  }, [epiReportState]);

  return (
    <>
      <div className="report mt-top">
        <div className="card card-style">
          <div className="content mb-0">
            <h1 className="mb-3">Transfer E-Pin Report</h1>
            <div className="divider mb-4"></div>
            <div className="table-responsive e-pinreport">
              <table className="table table-bordered">
                <thead style={{ background: "rgb(220, 155, 6)" }}>
                  <tr>
                    <th>#</th>
                    <th>To UserId</th>
                    <th>Available E-Pin</th>
                    <th>No Of Transfer E-Pin</th>
                    <th>Pin Amount</th>
                    <th>Total Amount</th>
                    <th>Entry Date</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="7" className="text-center">
                        <div
                          className="spinner-border text-black"
                          role="status"
                        ></div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((e, i) => (
                        <tr key={e.id}>
                          <td>{currentPage * itemsPerPage + i + 1}</td>
                          <td>{e?.toUserID}</td>
                          <td>{e?.availablePin}</td>
                          <td>{e?.transferedPin}</td>
                          <td>{e?.pinCost}</td>
                          <td>{e?.totalAmount}</td>
                          <td>{e?.entryDate}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
            <ReactPaginate
              previousLabel="< Previous"
              nextLabel="Next >"
              breakLabel="..."
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default TransferEpinReport;
