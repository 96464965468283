import { toast } from "react-toastify";
import ApiCalling from "../../../Services/ApiCalling";
import { useEffect } from "react";
import PayLoadObject from "../../../utils/payloadObj";

const Logout = () => {
 
const handleLogout = async () => {
    const bodyObject = {
      ...PayLoadObject(),
      sessType: "1",
      isUPI: false,
      regKey: "",
      serialNo: "34bb94497da8aff0",
      uid: 0,
    };
    try {
      const data = await ApiCalling.request(
        "POST",
        "App/Logout",
        bodyObject,
        null
      );
      if (data?.statuscode === 1) {
        toast.success("Logout Successfully! ", {
          toastId: "logout Success",
          autoClose: 700,
        });
        sessionStorage.clear();
        window.location.href = "/login";
      } else {
        toast.error(data?.msg, { toastId: "logout error" });
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  useEffect(() => {
    handleLogout();
  }, []);

  return <></>;
};
export default Logout;
