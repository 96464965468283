import { useEffect } from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter";
import ReferralTeamService from "./ReferralTeamService";
import ReactPaginate from "react-paginate";

const ReferralTeam = () => {
  const {
    fetchReferralInfo,
    getReferralList,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage
  } = ReferralTeamService();

  useEffect(() => {
    fetchReferralInfo();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(getReferralList.length / itemsPerPage));
    setCurrentItems(getReferralList.slice(0, itemsPerPage));
  }, [getReferralList]);

  return (
    <>
      <div className="mt-top">
        <div className="card card-style">
          <div className="content mb-0">
            <h1 className="mb-3">Referrals</h1>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead style={{ background: "rgb(220, 155, 6)" }}>
                  <tr>
                    <th>#</th>
                    <th>User Id</th>
                    <th>Name</th>
                    <th>MobileNo</th>
                    <th>Reg.Date</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <div
                          className="spinner-border text-black"
                          role="status"
                        ></div>
                      </td>
                    </tr>
                  ) : currentItems?.length > 0 ? (
                    currentItems.map((e, i) => (
                      <tr key={i}>
                        <td>{currentPage * itemsPerPage + i + 1}</td>
                        <td>
                          {e?.uPrefix}
                          {e?.userId}
                        </td>
                        <td>{e?.username}</td>
                        <td>{e?.mobileNo}</td>
                        <td>{e?.registerDate}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <ReactPaginate
              previousLabel="< Previous"
              nextLabel="Next >"
              breakLabel="..."
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default ReferralTeam;
