import React from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter";
import ViewProfileService from "./ViewProfileService.js";
import { useEffect, useState } from "react";
import ChangePinService from "./ChangePinService.js";
import KycUpdateService from "./KycUpdateService.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DATACONSTANT from "../../../../Services/DataConstant.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faUser } from "@fortawesome/free-solid-svg-icons";
import BodyService from "../../../Dashboard/Body/BodyService.js";

const ViewProfile = () => {
  const {
    fetchData,
    input,
    handleChange,
    updateProfile,
    userId,
    errors,
    fetchBankList,
    bankList,
  } = ViewProfileService();
  const { inputPin, handleChangePin, handleSubmitPin, loadingBtn, error } =
    ChangePinService();
  const { fetchHelpingPlanDashboard, fetchHelpDasboardData } = BodyService();
  const {
    fetchKycData,
    kycData,
    handleUpload,
    handleSubmitKyc,
    showModal,
    imageUrl,
    handleOpenModal,
    handleSubmitModal,
  } = KycUpdateService();
  const apibaseURL = process.env.REACT_APP_APIBASE_URL;
  const date = new Date().getTime();
  const prefix = sessionStorage.getItem("prefix");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    fetchData();
    fetchBankList();
    fetchKycData();
    fetchHelpingPlanDashboard();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile();
  };

  const handleDateChange = (date) => {
    handleChange({
      target: {
        name: "dob",
        value: date.toISOString().split("T")[0],
      },
    });
  };

  return (
    <div>
      <div className="main-section">
        <div className="card card-style">
          <img
            src={`${apibaseURL}Image/Website/1/logo.png?${date}}`}
            alt="Logo"
            className="mx-auto  over-card mt-3"
            width="250"
          />

          <div className="content">
            <h1 className="boxed-text-xl font-40"></h1>

            <div className="row text-center mb-3 mx-n2">
              <div className="col-4">
                <i className="fa fa-users color-brown font-20"></i>
                <h1
                  className="color-gray-dark font-20 font-400"
                  id="referral_team"
                >
                  {fetchHelpDasboardData?.totalDirectCount}
                </h1>
                <span className="font-9 d-block mt-n3">Referral Team</span>
              </div>
              <div className="col-4">
                <i className="fa fa-users color-green-dark font-20"></i>
                <h1
                  className="color-green-dark font-20 font-400"
                  id="active_team"
                >
                  {fetchHelpDasboardData?.activeDirect}
                </h1>
                <span className="font-9 d-block mt-n3">Active Team</span>
              </div>
              <div className="col-4">
                <i className="fa fa-users color-red-dark font-20"></i>
                <h1
                  className="color-red-dark font-20 font-400"
                  id="inactive_team"
                >
                  {fetchHelpDasboardData?.deActiveDirect}
                </h1>
                <span className="font-9 d-block mt-n3">In-Active Team</span>
              </div>
            </div>
            <p className="text-center">
              User Name :<strong id="user_userid">{input?.name}</strong>, User
              Id:
              <strong id="user_username">
                {prefix}
                {userId}
              </strong>
              , Referral Code :
              <strong id="user_sponsorid">
                {prefix}
                {input?.referalID}
              </strong>
              , MobileNo :<strong id="user_mobile">{input?.mobileNo}</strong>,
              E-mail Id :<strong id="user_email">{input?.emailID}</strong>
            </p>
          </div>
        </div>
        <a
          className="no-effect card card-style mb-2"
          data-toggle="collapse"
          href="#change_personal_info"
          role="button"
          aria-expanded="false"
          aria-controls="change_personal_info"
        >
          <div className="content">
            <div className="d-flex mb-n1">
              <div className="align-self-center">
                <i className="fa fa-user-edit color-green-dark font-35 fa-fw me-2"></i>
              </div>
              <div className="align-self-center">
                <h4>Update Personal Info</h4>
                <p className="opacity-80 font-10 mt-n2">Tap here to Expand</p>
              </div>
              <div className="ms-auto align-self-center text-center opacity-70">
                <i className="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i>
                <br />
              </div>
            </div>
          </div>
        </a>

        <div className="collapse" id="change_personal_info">
          <div className="card card-style">
            <div className="content mb-2">
              <h4 className="text-success">Personal Information:</h4>
              <div className="overlay-wrapper">
                <div
                  className="overlay"
                  style={{ display: "none" }}
                  id="loading"
                >
                  <i className="fas fa-3x fa-sync-alt fa-spin"></i>
                </div>

                <form
                  action="/Smart/EditProfile?Length=5"
                  data-ajax="true"
                  data-ajax-failure="fnFailure"
                  data-ajax-loading="#loading"
                  data-ajax-method="POST"
                  data-ajax-success="fnSuccess"
                  enctype="multipart/form-data"
                  id="form0"
                  method="post"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Name</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Enter name"
                          type="text"
                          value={input?.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Email</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="emailID"
                          name="emailID"
                          placeholder="Enter Email"
                          type="text"
                          value={input?.emailID}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Mobile No.</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="mobileNo"
                          name="mobileNo"
                          placeholder="Enter MobileNo"
                          type="text"
                          value={input?.mobileNo}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Alternate MobileNo.</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="alternateMobile"
                          name="alternateMobile"
                          placeholder="Enter alternate MobileNo"
                          type="text"
                          value={input?.alternateMobile}
                          onChange={handleChange}
                        />
                      </div>
                      <span>
                        {errors.alternateMobile && (
                          <small className="text-danger">
                            {errors.alternateMobile}
                          </small>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="dob">DOB</label>
                      <div className="input-group has-borders mb-4 ">
                        <DatePicker
                          className="form-control cus-date"
                          id="dob"
                          name="dob"
                          selected={input.dob}
                          onChange={handleDateChange}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Enter DOB"
                          popperPlacement="bottom-start"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "10px, 10px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                        />
                        <div
                          className="input-group-append"
                          style={{
                            position: "absolute",
                            right: "0",
                            height: "53px",
                          }}
                        >
                          <span
                            className="input-group-text"
                            style={{ background: "rgb(220, 155, 6)" }}
                          >
                            <i className="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label>Aadhar No.</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="aadhar"
                          name="aadhar"
                          placeholder="Enter Aadhar number"
                          type="text"
                          value={input?.aadhar}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Address</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <textarea
                          className="form-control"
                          cols="20"
                          id="address"
                          name="address"
                          placeholder="Enter address"
                          rows="2"
                          value={input?.address}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label>Landmark</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="landmark"
                          name="landmark"
                          placeholder="Enter landmark"
                          type="text"
                          value={input?.landmark}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>City</label>
                      <div className="input-style has-borders  mb-4">
                        <input
                          className="form-control"
                          id="city"
                          name="city"
                          placeholder="Enter city"
                          type="text"
                          readOnly
                          value={input?.city}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>PinCode</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="pincode"
                          name="pincode"
                          placeholder="Enter PinCode"
                          type="text"
                          value={input?.pincode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <span>
                      {errors.pincode && (
                        <small className="text-danger">{errors.pincode}</small>
                      )}
                    </span>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>State Name</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="stateName"
                          name="stateName"
                          placeholder="Enter stateName"
                          type="text"
                          value={input?.stateName}
                          //onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <h4 className="text-success">Account Information:</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <label>PAN No.</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="pan"
                          name="pan"
                          placeholder="Enter PanNumber"
                          type="text"
                          value={input?.pan}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label>Bank Name</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <select
                          className="form-control"
                          id="bankName"
                          name="bankName"
                          value={input?.bankName}
                          onChange={handleChange}
                        >
                          <option value="">Select a bank</option>
                          {bankList?.map((bank) => (
                            <option key={bank.bankID} value={bank.bankName}>
                              {bank.bankName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Account Holder Name</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="accountName"
                          name="accountName"
                          placeholder="Enter accountHolder name"
                          type="text"
                          value={input?.accountName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Account Number</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="accountNumber"
                          name="accountNumber"
                          placeholder="Enter accountNumber"
                          type="text"
                          value={input?.accountNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <span>
                        {errors.accountNumber && (
                          <small className="text-danger">
                            {errors.accountNumber}
                          </small>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>IFSC</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="ifsc"
                          name="ifsc"
                          placeholder="Enter IFSC Code"
                          type="text"
                          value={input?.ifsc}
                          onChange={handleChange}
                        />
                      </div>
                      <span>
                        {errors.ifsc && (
                          <small className="text-danger">{errors.ifsc}</small>
                        )}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label>Branch Name</label>
                      <div className="input-style has-borders no-icon validate-field mb-4">
                        <input
                          className="form-control"
                          id="branchName"
                          name="branchName"
                          placeholder="Enter branch name"
                          type="text"
                          value={input?.branchName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {kycData.some(
                      (doc) => doc.kycStatus === 1 || doc.kycStatus === 5
                    ) && (
                      <button
                        type="submit"
                        className="btn btn-l rounded-sm btn-full btn-warning font-700 text-uppercase mb-2 mt-3"
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <a
          className="no-effect card card-style mb-2"
          data-toggle="collapse"
          href="#kyc_info"
          role="button"
          aria-expanded="false"
          aria-controls="kyc_info"
        >
          <div className="content">
            <div className="d-flex mb-n1">
              <div className="align-self-center">
                <i className="fa fa-edit color-green-dark font-35 fa-fw me-2"></i>
              </div>
              <div className="align-self-center">
                <h4>Upload KYC</h4>
                <p className="opacity-80 font-10 mt-n2">Tap here to Expand</p>
              </div>
              <div className="ms-auto align-self-center text-center opacity-70">
                <i className="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i>
                <br />
              </div>
            </div>
          </div>
        </a>
        <div className="collapse" id="kyc_info">
          <div className="card card-style">
            <div className="content">
              <h4>KYC Documents</h4>
              <table className="table table-bordered">
                <thead style={{ background: "rgb(220, 155, 6)" }}>
                  <tr>
                    <th>Document Name</th>
                    <th>Verify Status</th>
                    <th>Upload</th>
                  </tr>
                </thead>
                <tbody>
                  {kycData.map((doc) => (
                    <tr key={doc.id}>
                      <td>{doc.docName}</td>
                      <td
                        style={{
                          color:
                            DATACONSTANT.KYC_STATUSTYPE[doc.kycStatus]?.color,
                        }}
                      >
                        {DATACONSTANT.KYC_STATUSTYPE[doc.kycStatus]?.text}
                      </td>
                      <td>
                        {doc.kycStatus !== 3 && doc.kycStatus !== 2 ? (
                          <input
                            type="file"
                            className="text-success"
                            onChange={(e) => handleUpload(e, doc?.docTypeID)}
                          />
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary  btn-cus-main"
                            onClick={() => handleOpenModal(doc?.docUrl)}
                          >
                            VIEW
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div>
                {kycData.some(
                  (doc) => doc.kycStatus === 1 || doc.kycStatus === 5
                ) && (
                  <button
                    onClick={handleSubmitKyc}
                    className="btn btn-l rounded-sm btn-full btn-warning font-700 text-uppercase mb-2 mt-3"
                  >
                    UPDATE
                  </button>
                )}
              </div>
            </div>
          </div>

          <div
            className={`modal fade-Modalclass  ${showModal ? "show" : ""}`}
            tabIndex="-1"
            role="dialog"
            style={{ display: showModal ? "block" : "none" }}
          >
            <div
              className="modal-dialog "
              role="document"
              style={{ maxWidth: "692px" }}
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  style={{ color: "red", textAlign: "right", padding: "5px" }}
                  onClick={handleSubmitModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <div className="modal-body">
                  <img
                    src={imageUrl}
                    alt="KYC Document"
                    style={{ width: "100%", height: "350px" }}
                    className="transition"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <a
          className="no-effect card card-style mb-2"
          data-toggle="collapse"
          href="#change_login_password"
          role="button"
          aria-expanded="false"
          aria-controls="change_login_password"
        >
          <div className="content">
            <div className="d-flex mb-n1">
              <div className="align-self-center">
                <i className="fa fa-power-off color-red-dark font-35 fa-fw me-2"></i>
              </div>
              <div className="align-self-center">
                <h4>Change Login Password</h4>
                <p className="opacity-80 font-10 mt-n2">Tap here to Expand</p>
              </div>
              <div className="ms-auto align-self-center text-center opacity-70">
                <i className="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i>
                <br />
              </div>
            </div>
          </div>
        </a>

        <div className="collapse" id="change_login_password">
          <div className="card card-style">
            <div className="content mb-2">
              <p>Change Login Password</p>
              <form
                action="/Smart/ChangeLoginPassword?Length=5"
                data-ajax="true"
                data-ajax-failure="fnFailure"
                data-ajax-loading="#loading"
                data-ajax-method="POST"
                data-ajax-success="fnSuccess"
                encType="multipart/form-data"
                id="form3"
                method="post"
              >
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="oldP">Old Password</label>
                    <div className="input-group mb-4">
                      <input
                        className="form-control cus-pas"
                        id="oldP"
                        name="oldP"
                        placeholder="Enter Old Password"
                        required
                        type={showOldPassword ? "text" : "password"}
                        value={inputPin?.oldP}
                        onChange={handleChangePin}
                      />
                      <div
                        className="input-group-append"
                        style={{ background: "rgb(220, 155, 6)" }}
                      >
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                          <FontAwesomeIcon
                            icon={showOldPassword ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                    </div>
                    {error.oldP && (
                      <div className="help-block text-danger">{error.oldP}</div>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="NewP">New Password</label>
                    <div className="input-group mb-4">
                      <input
                        className="form-control cus-pas"
                        id="NewP"
                        name="NewP"
                        placeholder="Enter New Password"
                        required
                        type={showNewPassword ? "text" : "password"}
                        value={inputPin?.NewP}
                        onChange={handleChangePin}
                      />
                      <div
                        className="input-group-append"
                        style={{ background: "rgb(220, 155, 6)" }}
                      >
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          <FontAwesomeIcon
                            icon={showNewPassword ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                    </div>
                    {error.NewP && (
                      <div className="help-block text-danger">{error.NewP}</div>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="ConfirmP">Confirm Password</label>
                    <div className="input-group mb-4">
                      <input
                        className="form-control cus-pas"
                        id="ConfirmP"
                        name="ConfirmP"
                        placeholder="Enter Confirm Password"
                        required
                        type={showConfirmPassword ? "text" : "password"}
                        value={inputPin?.ConfirmP}
                        onChange={handleChangePin}
                      />
                      <div
                        className="input-group-append"
                        style={{ background: "rgb(220, 155, 6)" }}
                      >
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          <FontAwesomeIcon
                            icon={showConfirmPassword ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                    </div>
                    {error.ConfirmP && (
                      <div className="help-block text-danger">
                        {error.ConfirmP}
                      </div>
                    )}
                  </div>
                </div>

                <div className="divider mb-3"></div>
                <button
                  type="button"
                  className="btn btn-l rounded-sm btn-full btn-warning font-700 text-uppercase mb-2 mt-3"
                  name="submit"
                  onClick={handleSubmitPin}
                  disabled={loadingBtn}
                >
                  {loadingBtn ? "Updating..." : " Update"}
                </button>
              </form>
            </div>
          </div>
        </div>

        <BottomFooter />
      </div>
    </div>
  );
};
export default ViewProfile;
