/* eslint-disable import/no-anonymous-default-export */
import ApiCalling from "../../../../Services/ApiCalling";
import PayLoadObject from "../../../../utils/payloadObj";

class TransferEpinService {

  getTransferEpin = async () => {
    const data = PayLoadObject();
    return await ApiCalling.request("Post", "App/GetEPINPackage", data, null);
  };

  fetchUserName = async (userID) => {
    return await ApiCalling.request(
      "POST",
      `GetUserNameByID?userID=${userID}`,
      null,
      null
    );
  };

  postTransferData = async (body) => {
    return await ApiCalling.request("Post", "App/TransferEpin", body, null);
  };
}

export default new TransferEpinService();
