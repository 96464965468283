import  { useState } from 'react'
import ApiCalling from '../../../Services/ApiCalling'
import PayLoadObject from '../../../utils/payloadObj'

const BodyService = () => {
  const[fetchHelpDasboardData,setFetchHelpDasboardData]=useState({})

const fetchHelpingPlanDashboard=async()=>{
  const ObjectBody= PayLoadObject();
  const res= await ApiCalling.request("POST","App/HelpingPlanDashboard",ObjectBody,null)
  if(res?.statuscode===1)
  {
setFetchHelpDasboardData(res?.data);
  }
}

  return {fetchHelpDasboardData,fetchHelpingPlanDashboard}
}

export default BodyService;