
const PayLoadObject = () => {
  const session = sessionStorage.getItem("session");
  const sessionID = sessionStorage.getItem("sessionID");
  const userID = sessionStorage.getItem("userID");
  const domainName=  process.env.REACT_APP_APIDOMAIN_NAME;
  
  
return {
      loginTypeID: 1,
      session: session,
      sessionID: sessionID,
      domain: domainName,
      appid: "ROUNDPAYAPPID13APR20191351",
      imei: "NA",
      userID: userID,
      version: "1.16",
      serialNo: "YLMBSWYTUGLBJNJV",
      regKey: "",
  
  };
};

export default PayLoadObject;
