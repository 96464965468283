/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter";
import TransferEPinService from "./TransferEPinService.js";
import debounce from "lodash/debounce";
import { customFormValidate } from "../../../../Services/CustomValidationService.js";
import { toast } from "react-toastify";
import PayLoadObject from "../../../../utils/payloadObj.js";
const TransferEpin = () => {
  const [epinPackage, setEpinPackage] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [epinType, setEpinType] = useState("");
  const [inputValues, setInputValues] = useState({
    count: "",
    cost: "",
    pinType: "",
  });
  const [transferValues, setTransferValue] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [errors, setErrors] = useState({});
  const [uniquePinTypes, setUniquePinTypes] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    const pinTypesSet = new Set(epinPackage.map((e) => e.pinType));
    setUniquePinTypes(Array.from(pinTypesSet));
  }, [epinPackage]);

  const getTransferEpin = async () => {
    const allEpinPackage = await TransferEPinService.getTransferEpin();

    if (allEpinPackage.statuscode === 1) {
      setEpinPackage(allEpinPackage.data);
    }
  };

  const debouncedFetchUserName = useCallback(
    debounce(async (e) => {
      const user = e.target.value;
      setUserId(user);
      if (!user) {
        return;
      }
      const data = await TransferEPinService.fetchUserName(user);
      if (data.name) {
        setUserName(data.name);
      } else {
        toast.error("Invalid User", { toastId: "Invalid User" });
        setUserName("");
      }
    }, 3000),
    []
  );

  useEffect(() => {
    getTransferEpin();

    return () => {
      setEpinPackage([]);
    };
  }, []);

  const handleEPinTYpeChange = (e) => {
    const selectedPinType = e.target.value;
    const selectedData = epinPackage.find(
      (item) => item.pinType === Number(selectedPinType)
    );
    if (selectedData) {
      setInputValues({ count: "", cost: "" });
      setEpinType(selectedData.pinType);
      setTotalAmount("");
      setTransferValue("");
    } else {
      setInputValues("");
    }
  };

  const handleEPackageChange = (e) => {
    const selectedCost = e.target.value;
    const selectedData = epinPackage.find(
      (item) => item.cost === Number(selectedCost)
    );
    if (selectedData) {
      setInputValues(selectedData);
      setTotalAmount("");
      setTransferValue("");
    } else {
      setInputValues({ count: "", cost: "", pinType: "" });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value > inputValues.count) {
      toast.error(
        "Transfer-Epin Count Should be less than or equal to Available-Epin",
        { toastId: "TRANSFER EPIN ERROR", className: "custom-toast-mobile" }
      );
      return;
    }
    setTransferValue(value);
    const multiplied = value * inputValues.cost;
    setTotalAmount(multiplied);
  };

  const onHandelSubmit = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    const obj = {
      userId: userId,
      userName: userName,
      cost: inputValues.cost,
      count: inputValues.count,
      PinType: epinType,
      transferPin: transferValues,
      total: totalAmount,
    };

    const formErrors = customFormValidate(obj);
    setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      if (Object.values(formErrors).some((e) => e !== "")) {
        toast.error("Please fill all required field!.", {
          toastId: "Required Field",
          position: "top-center",
        });
        setLoadingBtn(false);
        return;
      }
    }
    const body = {
      appSession: PayLoadObject(),
      Request: {
        AssignedTo: userId,
        Cost: inputValues.cost,
        PinCount: transferValues,
        PinType: epinType,
      },
    };
    const res = await TransferEPinService.postTransferData(body);
    if (res.statuscode === 1) {
      setTotalAmount("");
      setTransferValue("");
      setUserId("");
      setUserName("");
      toast.success(res.data.msg, { toastId: "Transfer Success" });
    } else {
      toast.error(res.msg, { toastId: "Transfer Error" });
    }
    setLoadingBtn(false);
  };

  return (
    <>
      <div className="epTransfer mt-top">
        <div className="card card-style">
          <div className="content mb-0">
            <h1 className="mb-3">Transfer E-Pin</h1>

            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-style has-borders no-icon validate-field">
                    <p>
                      To User Id<sup className="text-danger">*</sup>
                    </p>
                    <input
                      name="TransferUserId"
                      onChange={debouncedFetchUserName}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-style has-borders">
                    <p style={{ margin: "0 !important" }}>
                      To User Name<sup className="text-danger">*</sup>
                    </p>
                    <input
                      className="form-control"
                      id="TransferUserName"
                      name="TransferUserName"
                      type="text"
                      value={userName}
                      readOnly
                    />
                  </div>
                </div>

                {epinPackage.length > 0 && (
                  <div className="col-md-6">
                    <div className="input-style has-borders no-icon validate-field">
                      <p style={{ margin: "0 !important" }}>
                        E-Pin Type<sup className="text-danger">*</sup>
                      </p>
                      <select
                        id="ddPlan"
                        name="PlanId"
                        onChange={handleEPinTYpeChange}
                      >
                        <option value="" selected disabled>
                          Select e-pin type
                        </option>
                        {uniquePinTypes.map((pinType, index) => (
                          <option key={index} value={pinType}>
                            {pinType === 1 ? "Registration" : "Activation"}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                {epinPackage.length > 0 && (
                  <div className="col-md-6">
                    <div className="input-style has-borders no-icon validate-field">
                      <p style={{ margin: "0 !important" }}>
                        E-Pin Package<sup className="text-danger">*</sup>
                      </p>
                      <select
                        id="ddPlan"
                        name="PlanId"
                        value={inputValues?.cost}
                        onChange={handleEPackageChange}
                        disabled={epinType === ""}
                      >
                        <option value="" selected disabled>
                          Select package cost
                        </option>
                        {epinPackage
                          .filter((e) => e.pinType === epinType)
                          .map((e, index) => (
                            <option key={index} value={e.cost}>
                              {e.cost}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                {epinPackage.length > 0 && (
                  <div className="col-md-6">
                    <div className="input-style has-borders no-icon validate-field mb-4">
                      <p style={{ margin: "0 !important" }}>
                        E-Pin Amount<sup className="text-danger">*</sup>
                      </p>
                      <input
                        className="form-control"
                        data-val="true"
                        data-val-number="The field Amount must be a number."
                        data-val-required="The Amount field is required."
                        id="Amount"
                        name="Amount"
                        readOnly
                        type="text"
                        value={inputValues.cost}
                      />
                    </div>
                  </div>
                )}
                {epinPackage.length > 0 && (
                  <div className="col-md-6">
                    <div className="input-style has-borders no-icon validate-field mb-4">
                      <p style={{ margin: "0 !important" }}>
                        Available E-Pin<sup className="text-danger">*</sup>
                      </p>
                      <input
                        className="form-control"
                        id="AvailableEPin"
                        name="AvailableEPin"
                        readOnly
                        type="text"
                        value={inputValues.count}
                      />
                    </div>
                  </div>
                )}
                {epinPackage.length > 0 && (
                  <div className="col-md-6">
                    <div className="input-style has-borders no-icon validate-field mb-4">
                      <p style={{ margin: "0 !important" }}>
                        Transfer E-Pin<sup className="text-danger">*</sup>
                      </p>
                      <input
                        max={inputValues.count}
                        value={transferValues}
                        type="text"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="input-style has-borders no-icon validate-field mb-4">
                    <p style={{ margin: "0 !important" }}>
                      Total Amount<sup className="text-danger">*</sup>
                    </p>
                    <input
                      className="form-control"
                      value={totalAmount}
                      name="TotalAmount"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="divider mb-4"></div>
                  <button
                    className="btn btn-l rounded-sm btn-full btn-warning font-700 text-uppercase mb-2 mt-3 button"
                    name="submit"
                    id="btnTransferNow"
                    onClick={onHandelSubmit}
                    disabled={loadingBtn}
                  >
                    {loadingBtn ? "Transferring..." : "Transfer Now"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default TransferEpin;
