/* eslint-disable import/no-anonymous-default-export */
import ApiCalling from "../../../../Services/ApiCalling";
import PayLoadObject from '../../../../utils/payloadObj'
class EpinReEpinReportService {
  getTransferEpinReport = async () => {
    const data = PayLoadObject()
    return await ApiCalling.request("Post", "App/GetTransferEpinReport", data, null);
  };
}

export default new EpinReEpinReportService();
