import React, { useEffect } from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter.jsx";
import UsedEpinService from "./UsedEpinService.js";
import ReactPaginate from "react-paginate";

const UsedEpinReport = () => {
  const {
    getUsedEpinList,
    fetchUsedEpinList,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  } = UsedEpinService();

  useEffect(() => {
    fetchUsedEpinList();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(getUsedEpinList.length / itemsPerPage));
    setCurrentItems(getUsedEpinList.slice(0, itemsPerPage));
  }, [getUsedEpinList]);

  return (
    <>
      <div className="report mt-top">
        <div className="card card-style">
          <div className="content mb-0">
            <h1 className="mb-3">Used E-Pin Report</h1>
            <div className="divider mb-4"></div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead style={{ background: "rgb(220, 155, 6)" }}>
                  <tr>
                    <th>#</th>
                    <th>Epin</th>
                    <th>Cost</th>
                    <th>isActive</th>
                    <th>AssignedTo</th>
                    <th>Entry Date</th>
                    <th>Used by</th>
                    <th>Used Date</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="8" className="text-center">
                        <div
                          className="spinner-border text-black"
                          role="status"
                        ></div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    {currentItems.length > 0 ? (
                      <tbody>
                        {currentItems.map((e, i) => (
                          <tr key={e.id}>
                            <td>{currentPage * itemsPerPage + i + 1}</td>
                            <td>{e?.epin}</td>
                            <td>{e?.cost}</td>
                            <td>{e?.isActive ? "True" : "False"}</td>
                            <td>{e?.assignedTo}</td>
                            <td>{e?.entryDate}</td>
                            <td>{e?.usedBy}</td>
                            <td>{e?.usedDate}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="8" className="text-center">
                            No data available
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </>
                )}
              </table>
            </div>
            <ReactPaginate
              previousLabel="< Previous"
              nextLabel="Next >"
              breakLabel="..."
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default UsedEpinReport;
