/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter.jsx";
import { Link } from "react-router-dom";
import LevelWiseService from "./LevelWiseService.js";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";

const LevelWiseTeam = () => {
  const {
    fetchLevelWiseTeamData,
    getLevelWiseData,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  } = LevelWiseService();

  useEffect(() => {
    fetchLevelWiseTeamData();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(getLevelWiseData.length / itemsPerPage));
    setCurrentItems(getLevelWiseData.slice(0, itemsPerPage));
  }, [getLevelWiseData]);

  return (
    <>
      <div className="mt-top">
        <div className="card card-style">
          <div className="content mb-0">
            <h1 className="mb-3">Level Wise Team</h1>
            <div className="divider mb-4"></div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead style={{ background: "rgb(220, 155, 6)" }}>
                  <tr>
                    <th>#</th>
                    <th>Level</th>
                    <th>Team</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <div
                          className="spinner-border text-black"
                          role="status"
                        ></div>
                      </td>
                    </tr>
                  ) : currentItems?.length > 0 ? (
                    currentItems.map((e, i) => (
                      <tr key={i}>
                        <td>{currentPage * itemsPerPage + i + 1}</td>
                        <td>{e?.level}</td>
                        <td>{e?.count}</td>
                        <td>
                          <Link to={`/sponserInfo?level=${e.level}`}>
                            View Detail
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <ReactPaginate
              previousLabel="< Previous"
              nextLabel="Next >"
              breakLabel="..."
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default LevelWiseTeam;
