/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter.jsx";

const TopReceivers = () => {
  return (
    <>
      <div className="mt-top"></div>
      <BottomFooter />
    </>
  );
};

export default TopReceivers;
