/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const BottomFooter = () => {
    const apibaseURL = process.env.REACT_APP_APIBASE_URL;
    const date = new Date().getTime();
    return (
        <>
            <div className="bottom-footer mb-bottom">
                <div className="footer card card-style">
                    <a href="#" className="footer-title logo-img">
                        <img src={`${apibaseURL}Image/Website/1/logo.png?${date}}`} alt="Logo" style={{ "width": "150px" }} />
                    </a>
                    <br />
                    <div className="text-center mb-3">
                        <a href="#" className="icon icon-xs rounded-sm shadow-l me-1 bg-facebook"><i className="fab fa-facebook-f"></i></a>
                        <a href="#" className="icon icon-xs rounded-sm shadow-l me-1 bg-twitter"><i className="fab fa-twitter"></i></a>
                        <a href="#" className="icon icon-xs rounded-sm shadow-l me-1 bg-phone"><i className="fa fa-phone"></i></a>
                        <a href="#" data-menu="menu-share" className="icon icon-xs rounded-sm me-1 shadow-l bg-red-dark"><i className="fa fa-share-alt"></i></a>
                        <a href="#" className="back-to-top icon icon-xs rounded-sm shadow-l bg-dark-light"><i className="fa fa-angle-up"></i></a>
                    </div>
                    <p className="footer-copyright">Copyright © Enabled <span id="copyright-year">2024</span>. All Rights Reserved.</p>
                    <p className="footer-links"><a href="#" className="color-highlight">Privacy Policy</a> | <a href="#" className="color-highlight">Terms and Conditions</a> | <a href="#" className="back-to-top color-highlight"> Back to Top</a></p>
                    <div className="clear"></div>
                </div>
            </div>

        </>
    )
}

export default BottomFooter