import React, { useEffect } from "react";
import BottomFooter from "../../Dashboard/BottomFooter/BottomFooter";
import LevelWiseLinkChartService from "./LevelWiseLinkChartService";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const LevelWiseLinkChart = () => {
  const {
    fetchLevelWiseLinkChart,
    getLevelWiseLinkChart,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  } = LevelWiseLinkChartService();

  useEffect(() => {
    fetchLevelWiseLinkChart();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(getLevelWiseLinkChart.length / itemsPerPage));
    setCurrentItems(getLevelWiseLinkChart.slice(0, itemsPerPage));
  }, [getLevelWiseLinkChart]);

  return (
    <>
      <div className="mt-top">
        <div className="card card-style">
          <div className="content mb-0">
            <h1 className="mb-3">Level Wise Link Chart</h1>
            <div className="divider mb-4"></div>
            <div className="table-responsive e-pinreport">
              <table className="table table-bordered">
                <thead style={{ background: "rgb(220, 155, 6)" }}>
                  <tr>
                    <th>#</th>
                    <th>Level</th>
                    <th>No Of Link</th>
                    <th>Give Amount</th>
                    <th>Take Amount</th>
                    <th>Give Help</th>
                    <th>Take Help</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="7" className="text-center">
                        <div
                          className="spinner-border text-black"
                          role="status"
                        ></div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {currentItems?.length > 0 ? (
                      currentItems?.map((e, i) => (
                        <tr key={e.level}>
                          <td>{currentPage * itemsPerPage + i + 1}</td>
                          <td>{e?.level}</td>
                          <td>{e?.noOfLink}</td>
                          <td>{e?.giveHelp}</td>
                          <td>{e?.takeHelp}</td>
                          <td>
                            <Link to={`/giveHelp?level=${e?.level}`}>
                              View Link
                            </Link>
                          </td>
                          <td>
                            <Link to={`/takeHelpRecieve?level=${e?.level}`}>
                              View Link
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
            <ReactPaginate
              previousLabel="< Previous"
              nextLabel="Next >"
              breakLabel="..."
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
          </div>
        </div>
      </div>

      <BottomFooter />
    </>
  );
};

export default LevelWiseLinkChart;
