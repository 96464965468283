import { useState } from "react";
import ApiCalling from "../../../../Services/ApiCalling";
import PayLoadObject from "../../../../utils/payloadObj";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const TakeHelpRecieveService = () => {
  const [getTakeHelp, setGetTakeHelp] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const levelNo = queryParams.get("level");
  const [currentItems, setCurrentItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    const offset = selected * itemsPerPage;
    setCurrentItems(getTakeHelp.slice(offset, offset + itemsPerPage));
    setCurrentPage(selected);
  };

  const fetchTakeHelp = async () => {
    const objectBody = {
      appSession: { ...PayLoadObject() },
      Request: {
        Level: levelNo,
        PoolId: "1",
      },
    };
    const res = await ApiCalling.request(
      "POST",
      "App/TakeHelpLink",
      objectBody,
      null
    );
    if (res.statuscode === 1) {
      setGetTakeHelp(res?.data);
    }
    setLoading(false);
  };

  const handleActionClick = async (ID, Status) => {
    const objectBody = {
      appSession: { ...PayLoadObject() },
      Request: {
        ID: ID,
        Status: +Status,
      },
    };
    const res = await ApiCalling.request(
      "POST",
      "App/EpinAccRej",
      objectBody,
      null
    );
    if (res.statuscode === 1) {
      toast.success(res?.msg, { toastId: "AcceptReject" });
      fetchTakeHelp();
    } else {
      toast.error(res?.msg, { toastId: "AcceptReject Error" });
    }
  };

  const handleSearch = () => {
    const dataArray = Array.isArray(getTakeHelp) ? getTakeHelp : [];
    const searchTerm = searchValue.trim();
    if (!searchTerm) {
      setCurrentItems(dataArray.slice(0, itemsPerPage));
      setPageCount(Math.ceil(dataArray.length / itemsPerPage));
      return;
    }
    const filteredData = dataArray.filter(
      (item) =>
        item.prefix.trim() + item.userID === searchTerm ||
        item.prefix + item.userID === searchTerm ||
        item.prefix.trim().toLowerCase() + item.userID === searchTerm ||
        item.prefix.toLowerCase() + item.userID === searchTerm ||
        item.userID === +searchTerm ||
        item.mobileNo == searchTerm
    );
    setCurrentItems(filteredData.slice(0, itemsPerPage));
    setPageCount(Math.ceil(filteredData.length / itemsPerPage));
  };
  return {
    fetchTakeHelp,
    getTakeHelp,
    searchValue,
    handleActionClick,
    handleSearch,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setSearchValue,
    setCurrentItems,
    currentPage,
  };
};

export default TakeHelpRecieveService;
