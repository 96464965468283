import axios from 'axios';
import { toast } from 'react-toastify';

class ApiCalling {
    constructor() {
        this.apiClient = axios.create({
            baseURL: process.env.REACT_APP_APIBASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.apiClient.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                console.log("errrr", error)
                return Promise.reject(error);
            }
        );

        this.apiClient.interceptors.response.use(
            (response) => {
                if (response.data?.msg?.includes("(redirectToLogin)Session")) {
                    toast.error('Session expired, Please login');
                    window.location.assign('/login');
                }
                return response.data;
            },
            (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    console.log('Unauthorized, logging out...');
                }
                return Promise.reject(error);
            }
        );
    }

    request(method, url, data = null, params = null) {
        return this.apiClient({
            method,
            url,
            data,
            params,
        });
    }


    formDataRequest(method, url, formData) {
        return this.apiClient({
            method,
            url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export default new ApiCalling();