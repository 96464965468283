/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import { ThemeProvider, ThemeContext } from "../../../utils/ThemeContext";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const apibaseURL = process.env.REACT_APP_APIBASE_URL;
  const date = new Date().getTime();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div class="header header-fixed header-logo-center">
        <Link to={"/dashboard"} class="header-title">
          <img
            src={`${apibaseURL}Image/Website/1/logo.png?${date}}`}
            alt="Logo"
            className="logo-img"
            style={{ width: "150px" }}
          />
        </Link>
        <a
          onClick={goBack}
          navigate
          data-back-button=""
          class="header-icon header-icon-1 cursor-pointer"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
      </div>

      {/* <div className={`app ${theme}`}>
      <header className="app-header">
        <h1>{theme === 'light' ? 'Light Mode' : 'Dark Mode'}</h1>
        <button onClick={toggleTheme}>
          Switch to {theme === 'light' ? 'Dark' : 'Light'} Mode
        </button>
      </header>
    </div> */}
    </>
  );
};

export default Header;
