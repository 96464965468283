import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import { ThemeProvider } from "./utils/ThemeContext";
import { AuthProvider } from "./utils/AuthContext";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Body from "./Component/Dashboard/Body/Body.jsx";
import Login from "./Component/Pages/Login/Login";
import SignUp from "./Component/Pages/SignUp/SignUp";
import Account from "./Component/ViewAccount/Account/Account.jsx";
import Idcard from "./Component/ViewAccount/Pages/Idcard/Idcard.jsx";
import UsedEpinReport from "./Component/ViewAccount/Pages/UsedEpinReport/UsedEpinReport.jsx";
import UnUsedEpinReport from "./Component/ViewAccount/Pages/UnUsedEpinReport/UnUsedEpinReport.jsx";
import TransferEpin from "./Component/ViewAccount/Pages/TransferEpin/TransferEpin.jsx";
import TransferEpinReport from "./Component/ViewAccount/Pages/TransferEpinReport/TransferEpinReport.jsx";
import TransactionHistory from "./Component/ViewAccount/Pages/TransactionHistory/TransactionHistroy.jsx";
import ViewProfile from "./Component/ViewAccount/Pages/ViewProfile/ViewProfile.jsx";
import Logout from "./Component/Pages/Logout/Logout";
import ProtectedRoute from "./Component/ProtectedRoute/ProtectedRoute";
import LevelWiseLinkChart from "./Component/ViewGiveTakeHelps/LevelWiseLinkChart/LevelWiseLinkChart.jsx";
import GiveHelp from "./Component/ViewGiveTakeHelps/Pages/GiveHelp/GiveHelp.jsx";
import TakeHelpRecieve from "./Component/ViewGiveTakeHelps/Pages/TakeHelpRecieve/TakeHelpRecieve.jsx";
import HelpPayment from "./Component/ViewGiveTakeHelps/Pages/HelpPayment/HelpPayment.jsx";
import Network from "./Component/ViewNetwork/Network/Network.jsx";
import LevelWiseTeam from "./Component/ViewNetwork/Pages/LevelWiseTeam/LevelWiseTeam.jsx";
import SponserInfo from "./Component/ViewNetwork/Pages/SponserInfo/SponserInfo.jsx";
import RefererralTeam from "./Component/ViewNetwork/Pages/ReferralTeam/ReferralTeam.jsx";
import TopReceivers from "./Component/ViewNetwork/Pages/TopReceivers/TopReceiver.jsx";

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <AuthProvider>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <>
                    <Body />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route
              path="/used-epin-report"
              element={
                <ProtectedRoute>
                  <UsedEpinReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/un-used-epin-report"
              element={
                <ProtectedRoute>
                  <UnUsedEpinReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transfer-epin"
              element={
                <ProtectedRoute>
                  <TransferEpin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transfer-epin-report"
              element={
                <ProtectedRoute>
                  <TransferEpinReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transactionHistory"
              element={
                <ProtectedRoute>
                  <TransactionHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/idcard"
              element={
                <ProtectedRoute>
                  <Idcard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/viewProfile"
              element={
                <ProtectedRoute>
                  <ViewProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/giveHelp"
              element={
                <ProtectedRoute>
                  <GiveHelp />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/levelWiseLinkChart"
              element={
                <ProtectedRoute>
                  <LevelWiseLinkChart />
                </ProtectedRoute>
              }
            />
               <Route
              path="/takeHelpRecieve"
              element={
                <ProtectedRoute>
                  <TakeHelpRecieve />
                </ProtectedRoute>
              }
            /> 
            <Route
              path="/helpPayment"
              element={
                <ProtectedRoute>
                  <HelpPayment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/network"
              element={
                <ProtectedRoute>
                  <Network />
                </ProtectedRoute>
              }
            />
            <Route
              path="/levelWiseTeam"
              element={
                <ProtectedRoute>
                  <LevelWiseTeam />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sponserInfo"
              element={
                <ProtectedRoute>
                  <SponserInfo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/refererralTeam"
              element={
                <ProtectedRoute>
                  <RefererralTeam />
                </ProtectedRoute>
              }
            />
            <Route
              path="/topReceivers"
              element={
                <ProtectedRoute>
                  <TopReceivers />
                </ProtectedRoute>
              }
            />

            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
