import { useEffect, useState } from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter";
import TakeHelpRecieveService from "./TakeHelpRecieveService";
import DATACONSTANT from "../../../../Services/DataConstant.js";
import ReactPaginate from "react-paginate";

const TakeHelpRecieve = () => {
  const apibaseURL = process.env.REACT_APP_APIBASE_URL;

  const {
    fetchTakeHelp,
    getTakeHelp,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
    handleActionClick,
    setSearchValue,
    handleSearch,
    searchValue,
  } = TakeHelpRecieveService();

  useEffect(() => {
    fetchTakeHelp();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(getTakeHelp.length / itemsPerPage));
    setCurrentItems(getTakeHelp.slice(0, itemsPerPage));
  }, [getTakeHelp]);

  return (
    <>
      <div className="mt-top">
        <div className="card card-style">
          <div className="content mb-0" id="tab-group-1">
            <h1 className="mb-3"> Recieve Help</h1>
            <div className="clearfix mb-3"></div>
            <div
              data-bs-parent="#tab-group-1"
              className="collapse show"
              id="tab-2"
            >
              <div className="row cus-userid d-flex align-items-end ml-0 mr-0">
                <div className="col-7">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by User Id or Mobile No"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-5">
                  <div className="form-group cus-getdata-10">
                    <button
                      type="button"
                      className="btn btn-warning btn-xs cus-takehelp-btn"
                      onClick={handleSearch}
                    >
                      Get Data
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 table-responsive e-pinreport">
                <table className="table table-bordered">
                  <thead style={{ background: "rgb(220, 155, 6)" }}>
                    <tr>
                      <th>#</th>
                      <th>UserName</th>
                      <th>Mobile No</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Remark</th>
                      <th>Date</th>
                      <th>Receipt</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <div
                            className="spinner-border text-black"
                            role="status"
                          ></div>
                        </td>
                      </tr>
                    ) : currentItems.length > 0 ? (
                      currentItems.map((e, i) => (
                        <tr key={i}>
                          <td>{currentPage * itemsPerPage + i + 1}</td>
                          <td>
                            {e?.userName}
                            <br />
                            <span>
                              [UID:{e?.prefix}
                              {e?.userID}]
                            </span>
                          </td>
                          <td>{e?.mobileNo}</td>
                          <td>{e?.helpAmount}</td>
                          <td
                            style={{
                              color:
                                DATACONSTANT.GIVEHELP_STATUSTYPE[e?.status]
                                  ?.color,
                            }}
                          >
                            {DATACONSTANT.GIVEHELP_STATUSTYPE[e?.status]?.text}
                          </td>
                          <td>{e?.remark}</td>
                          <td>{e?.date}</td>
                          <td>
                            {e?.receipt ? (
                              <a
                                href={`${apibaseURL}${e.receipt}`}
                                className="btn-sm btn-success"
                                target="_blank"
                              >
                                View
                              </a>
                            ) : (
                              <span></span>
                            )}
                          </td>
                          <td>
                            {e?.status === 1 ? (
                              <div>
                                <a
                                  type="button"
                                  className="btn-sm btn-success text-white"
                                  onClick={() => handleActionClick(e?.id, "2")}
                                >
                                  Accept
                                </a>
                                &nbsp;&nbsp;
                                <a
                                  type="button"
                                  className="btn-sm btn-danger text-white"
                                  onClick={() => handleActionClick(e?.id, "3")}
                                >
                                  Reject
                                </a>
                              </div>
                            ) : (
                              <span
                                style={{
                                  color:
                                    DATACONSTANT.GIVEHELP_STATUSTYPE[e?.status]
                                      ?.color,
                                }}
                              >
                                {
                                  DATACONSTANT.GIVEHELP_STATUSTYPE[e?.status]
                                    ?.text
                                }
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <ReactPaginate
                previousLabel="< Previous"
                nextLabel="Next >"
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
              />
            </div>
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default TakeHelpRecieve;
