import ApiCalling from "../../../../Services/ApiCalling";
import PayLoadObject from "../../../../utils/payloadObj";
import { useState } from "react";

const UnUsedEpinReportService = () => {
  const [getUnUsedEpinList, setGetUnUsedEpinList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    const offset = selected * itemsPerPage;
    setCurrentItems(getUnUsedEpinList.slice(offset, offset + itemsPerPage));
    setCurrentPage(selected);
  };
  const fetchUnUsedEpinList = async () => {
    const objectBody = {
      appSession: PayLoadObject(),
      Request: {
        isUsed: false,
      },
    };
    const res = await ApiCalling.request(
      "POST",
      "App/GetEPINReport",
      objectBody,
      null
    );
    if (res?.statuscode === 1) {
      setGetUnUsedEpinList(res?.data);
    }
    setLoading(false);
  };

  return {
    getUnUsedEpinList,
    fetchUnUsedEpinList,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  };
};

export default UnUsedEpinReportService;
