import React from "react";
import Header from "../../Dashboard/Header/Header.jsx";
import Footer from "../../Dashboard/Footer/Footer.jsx";
import { Link } from "react-router-dom";
import Logout from "../../Pages/Logout/Logout.js";
const Account = () => {
  return (
    <>
    
      <div className="account main-section">
        <div className="card card-style">
          <div className="content my-0">
            <div className="list-group list-custom-small check-visited">
              <Link to={"/ViewProfile"}>
                <i className="fa fa-user font-14 color-green-dark"></i>
                <span>View Profile</span>
                <i className="fa fa-angle-right"></i>
              </Link>
              <Link to={'/idcard'} >
                <i className="fa fa-user font-14 color-green-dark"></i>
                <span>Id Card</span>
                <i className="fa fa-user"></i>
              </Link>
              <Link to={'/used-epin-report'} >
                <i className="fa fa-wallet font-14 color-green-dark"></i>
                <span>Used E-Pin Report</span>
                <i className="fa fa-angle-right"></i>
              </Link>
              <Link to={'/un-used-epin-report'}>
                <i className="fa fa-wallet font-14 color-green-dark"></i>
                <span>Unused E-Pin Report</span>
                <i className="fa fa-angle-right"></i>
              </Link>
              <Link to={'/transfer-epin'} style={{ display: "none" }}>
                <i className="fa fa-wallet font-14 color-green-dark"></i>
                <span>Transfered E-Pin Report</span>
                <i className="fa fa-angle-right"></i>
              </Link>
              <Link to={'/transfer-epin'}>
                <i className="fa fa-wallet font-14 color-green-dark"></i>
                <span>Transfer E-Pin</span>
                <i className="fa fa-angle-right"></i>
              </Link>
              <Link to={'/transfer-epin-report'}>
                <i className="fa fa-wallet font-14 color-green-dark"></i>
                <span>Transfer E-Pin Report</span>
                <i className="fa fa-angle-right"></i>
              </Link>
              <Link to={'/transactionHistory'}>
                <i className="fa fa-wallet font-14 color-green-dark"></i>
                <span>Transaction History</span>
                <i className="fa fa-angle-right"></i>
              </Link>

              <Link to="/logout">
                <i className="fa fa-donate font-14 color-green-dark"></i>
                <span>Logout</span>
                <i className="fa fa-angle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        </div>
       
      </>
      );
};

      export default Account;
