import { useState } from "react";
import ApiCalling from "../../../Services/ApiCalling";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../utils/AuthContext";
import PayLoadObject from "../../../utils/payloadObj.js"


const LoginService = () => {
  const { login } = useAuth() || {};
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [disableButton, setdisableButton] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleClick = async (e) => {
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    e.preventDefault();
    try {
      const bodyObject = {
        ...PayLoadObject(),
        userID: input.userID,
        password: input.password
      }
      setdisableButton(true);
      const data = await ApiCalling.request('POST', 'App/Login', bodyObject, null);
      if (data?.statuscode === 1) {
        toast.success(data?.msg, {toastId: "login success",autoClose:700});
        sessionStorage.setItem('isAuthenticated', 'true');
        sessionStorage.setItem('session', data?.data?.session);
        sessionStorage.setItem('sessionID', data?.data?.sessionID);
        sessionStorage.setItem('userID', data?.data?.userID);
        sessionStorage.setItem('name', data?.data?.name);
        sessionStorage.setItem('mobile', data?.data?.mobileNo);
        sessionStorage.setItem('email', data?.data?.emailID);
        sessionStorage.setItem('companyName', data?.data?.companyName);
        sessionStorage.setItem('joiningDate', data?.data?.joiningDate);
        sessionStorage.setItem('prefix', data?.data?.prefix);
        login();
        navigate('/dashboard');
        setdisableButton(false)
      } else {
        toast.error(data?.msg, {toastId: "login error"});
        setdisableButton(false)
      }
    } catch (error) {
      console.error('Error logging in:', error);
    //  toast.error('An error occurred during login. Please try again.');
      setdisableButton(false)
    }
  };


  const validateInput = () => {
    const newErrors = {};
    if (!input?.userID) {
      newErrors.userID = "Please enter User Id.";
    }

    if (!input?.password) {
      newErrors.password = "Please enter Passsword.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return {
    handleChange, input, handleClick, errors, showNewPassword, setShowNewPassword, disableButton
  }
}

export default LoginService;
