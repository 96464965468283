import { useState } from "react";
import ApiCalling from "../../../../Services/ApiCalling";
import PayLoadObject from "../../../../utils/payloadObj";

const ReferralTeamService = () => {
  const payload = PayLoadObject();
  const [getReferralList, setGetReferralList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    const offset = selected * itemsPerPage;
    setCurrentItems(getReferralList.slice(offset, offset + itemsPerPage));
    setCurrentPage(selected);
  };

  function formatDate(date) {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
  }

  const currentDate = new Date();

  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(currentDate.getFullYear() - 2);

  const toDate = formatDate(currentDate);
  const fromDate = formatDate(twoYearsAgo);

  const bodyObject = {
    appSession: {
      ...payload,
      ID: 0,
      uid: 0,
    },
    request: {
      DateFilter: "0",
      FromDate: fromDate,
      IsRecent: true,
      Leg: "All",
      LevelNo: 0,
      SearchType: "1",
      Status: "All",
      ToDate: toDate,
    },
  };

  const fetchReferralInfo = async () => {
    const res = await ApiCalling.request(
      "POST",
      "Dashboard/DirectMemberList",
      bodyObject,
      null
    );
    if (res?.statuscode === 1) {
      setGetReferralList(res?.data);
    }
    setLoading(false);
  };

  return {
    fetchReferralInfo,
    getReferralList,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  };
};

export default ReferralTeamService;
