
export const customFormValidate = (input) => {
    const errors = {};
    Object.keys(input).forEach((name) => {
        if(!input[name]) {
            errors[name] = name + " is required."
        } else {
            errors[name] = ''
        }
    });
    return errors;
};
