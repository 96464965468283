import { useEffect } from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter";
import GiveHelpService from "./GiveHelpService";
import { Link } from "react-router-dom";
import DATACONSTANT from "../../../../Services/DataConstant.js";
import ReactPaginate from "react-paginate";

const GiveHelp = () => {
  const {
    fetchGiveHelpData,
    getGiveHelp,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  } = GiveHelpService();

  useEffect(() => {
    fetchGiveHelpData();
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(getGiveHelp.length / itemsPerPage));
    setCurrentItems(getGiveHelp.slice(0, itemsPerPage));
  }, [getGiveHelp]);

  return (
    <>
      <div className="mt-top">
        <div className="card card-style">
          <div className="content mb-0" id="tab-group-1">
            <h1 className="mb-3">Give Help</h1>
            <div className="clearfix mb-3"></div>
            <div
              data-bs-parent="#tab-group-1"
              className="collapse show"
              id="tab-1"
            >
              <div className="table-responsive e-pinreport">
                <table className="table table-bordered">
                  <thead style={{ background: "rgb(220, 155, 6)" }}>
                    <tr>
                      <th>#</th>
                      <th>UserName</th>
                      <th>Mobile No</th>
                      <th>Help Amount</th>
                      <th>Help Status</th>
                      <th>Date</th>
                      <th>Remark</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td colSpan="8" className="text-center">
                          <div
                            className="spinner-border text-black"
                            role="status"
                          ></div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {currentItems.length > 0 ? (
                        currentItems.map((e, i) => (
                          <tr key={e.id}>
                            <td>{currentPage * itemsPerPage + i + 1}</td>
                            <td>
                              {e?.userName}
                              <br />
                              <span>
                                [UID:{e?.prefix}
                                {e?.userID}]
                              </span>
                            </td>
                            <td>{e?.mobileNo}</td>
                            <td>{e?.helpAmount}</td>
                            <td
                              style={{
                                color:
                                  DATACONSTANT.GIVEHELP_STATUSTYPE[e?.status]
                                    ?.color,
                              }}
                            >
                              {
                                DATACONSTANT.GIVEHELP_STATUSTYPE[e?.status]
                                  ?.text
                              }
                            </td>
                            <td>{e?.date}</td>
                            <td>{e?.remark}</td>
                            <td>
                              {e?.status === 0 || e?.status === 3 ? (
                                <Link
                                  to={"/helpPayment"}
                                  state={{
                                    helpAmount: e?.helpAmount,
                                    Level: e?.levelNo,
                                    USERID: e?.userID,
                                    Prefix: e?.prefix,
                                  }}
                                >
                                  <button className="btn btn-warning">
                                    PAY
                                  </button>
                                </Link>
                              ) : e?.status === 1 ? (
                                <span
                                  style={{
                                    color: "darkorange",
                                  }}
                                >
                                  Action Requested
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color:
                                      DATACONSTANT.GIVEHELP_STATUSTYPE[
                                        e?.status
                                      ]?.color,
                                  }}
                                >
                                  {
                                    DATACONSTANT.GIVEHELP_STATUSTYPE[e?.status]
                                      ?.text
                                  }
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
              <ReactPaginate
                previousLabel="< Previous"
                nextLabel="Next >"
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
              />
            </div>
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default GiveHelp;
