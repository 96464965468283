import { useState } from "react";
import PayLoadObject from "../../../../utils/payloadObj";
import ApiCalling from "../../../../Services/ApiCalling";

const LevelWiseService = () => {
  const [getLevelWiseData, setLevelWiseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const payload = PayLoadObject();
  const userId = payload.userID;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    const offset = selected * itemsPerPage;
    setCurrentItems(getLevelWiseData.slice(offset, offset + itemsPerPage));
    setCurrentPage(selected);
  };

  const dataobject = {
    appSession: {
      ...payload,
      ID: 0,
      uid: 0,
    },
    request: {
      MaxLevel: 10,
      Status: "All",
      UserId: userId,
    },
  };

  const fetchLevelWiseTeamData = async () => {
    const res = await ApiCalling.request(
      "POST",
      "GetLevelWiseCount",
      dataobject,
      null
    );
    if (res?.statuscode === 1) {
      setLevelWiseData(res?.data);
    }
    setLoading(false);
  };

  return {
    getLevelWiseData,
    fetchLevelWiseTeamData,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  };
};

export default LevelWiseService;
