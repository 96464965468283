import ApiCalling from "../../../../Services/ApiCalling";
import PayLoadObject from "../../../../utils/payloadObj";
import { useState } from "react";

const UsedEpinService = () => {
  const [getUsedEpinList, setGetUsedEpinList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    const offset = selected * itemsPerPage;
    setCurrentItems(getUsedEpinList.slice(offset, offset + itemsPerPage));
    setCurrentPage(selected);
  };

  const fetchUsedEpinList = async () => {
    const objectBody = {
      appSession: PayLoadObject(),
      Request: {
        isUsed: true,
      },
    };
    const res = await ApiCalling.request(
      "POST",
      "App/GetEPINReport",
      objectBody,
      null
    );
    if (res?.statuscode === 1) {
      setGetUsedEpinList(res?.data);
    }
    setLoading(false);
  };

  return {
    getUsedEpinList,
    fetchUsedEpinList,
    loading,
    currentItems,
    pageCount,
    itemsPerPage,
    handlePageClick,
    setPageCount,
    setCurrentItems,
    currentPage,
  };
};

export default UsedEpinService;
