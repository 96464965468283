import { useState, useEffect } from "react";
import ApiCalling from "../../../../Services/ApiCalling";
import { toast } from "react-toastify";
import PayLoadObject from "../../../../utils/payloadObj.js";

const KycUpdateService = () => {
  const [kycData, setKycData] = useState([]);
  const payload = PayLoadObject();
  const userId = payload.userID;
  const [inputoutletID, setOutletId] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const apibaseURL = process.env.REACT_APP_APIBASE_URL;

  const handleOpenModal = (docUrl) => {
    const imageSrc = `${apibaseURL}/Image/KYC/${docUrl}`;
    setImageUrl(imageSrc);
    setShowModal(true);
  };

  const handleSubmitModal = () => {
    setShowModal(false);
  };

  const fetchKycData = async () => {
    try {
      const objectBody = {
        ...PayLoadObject(),
        uid: userId,
      };
      const res = await ApiCalling.request(
        "POST",
        "App/AppDocumentDetails",
        objectBody,
        null
      );
      if (res?.statuscode === 1) {
        setKycData(res?.userDox);
        setOutletId(res?.userDox?.outletID);
      } else {
        toast.error(res?.userDox?.msg, { toastId: "Something went wrong" });
      }
    } catch (error) {
      console.error("Error fetching KYC data:", error);
      // toast.error('An error occurred while fetching the data. Please try again.');
    }
  };

  const handleUpload = async (e, id) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append(
      "userRequest",
      JSON.stringify({
        docTypeID: id,
        ...PayLoadObject(),
        uid: userId,
      })
    );
    let data = await ApiCalling.formDataRequest(
      "POST",
      "App/UploadDocs",
      formData
    );
    if (data.statuscode === 1) {
      toast.success(data.msg, { toastId: "Upload Successfully" });
    } else {
      toast.error(data?.msg, { toastId: "Upload Error" });
    }
  };

  const handleSubmitKyc = async () => {
    const requestBody = {
      outletID: inputoutletID,
      ...PayLoadObject(),
      uid: userId,
    };
    try {
      const res = await ApiCalling.request(
        "POST",
        "App/UpdateKYCStatus",
        requestBody,
        null
      );
      if (res?.statuscode === 1) {
        toast.success(res?.msg, { toastId: "Submission Success" });
        fetchKycData();
      } else {
        toast.error(res?.msg, { toastId: "Submission Error" });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return {
    kycData,
    fetchKycData,
    handleUpload,
    handleSubmitKyc,
    showModal,
    imageUrl,
    handleOpenModal,
    handleSubmitModal,
  };
};

export default KycUpdateService;
