/* eslint-disable jsx-a11y/alt-text */
import React, { useRef } from "react";

const Idcard = () => {
  const printRef = useRef();
  const handlePrint = () => {
    window.print();
  };
  const userId = sessionStorage.getItem("userID");
  const UserName = sessionStorage.getItem("name");
  const email = sessionStorage.getItem("email");
  const mobile = sessionStorage.getItem("mobile");
  const companyName = sessionStorage.getItem('companyName');
  const joiningDate = sessionStorage.getItem('joiningDate');
  const prefix = sessionStorage.getItem("prefix");
  const apibaseURL = process.env.REACT_APP_APIBASE_URL;
  const date = new Date().getTime();

  return (
    <>
      <div>
        <div
          className="container printableArea"
          style={{ margin: "100px auto" }}
          id="printableArea"
          ref={printRef}
        >
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-8">
              <div className="row text-center">
                <div
                  className="col-sm-12"
                  style={{
                    border: "3px solid rgb(8 76 133)", "border-radius": "14px"
                  }}
                >
                  <div className="" style={{ marginBottom: "3px!important " }}>
                    <div className="col-sm-12 text-center ">
                      <img src={`${apibaseURL}Image/Website/1/logo.png?${date}}`}
                        className='logo-img' alt="Logo"
                        style={{ maxWidth: "190px", marginTop: "10px" }} />
                      <hr style={{ background: "rgb(155 147 155)" }}
                      />
                    </div>
                  </div>
                  <div className="row my-0" style={{ marginBottom: "1px!important " }}>
                    <div
                      className="col-sm-12 text-center"
                      style={{ color: "#912c90" }}
                    >
                      <h3 style={{ marginTop: "-26px!important " }}>
                        <strong>{companyName.toUpperCase()}</strong>
                      </h3>
                    </div>
                  </div>
                  <div className="row my-1" >
                    <div className="col-sm-12 text-center" >
                      <h5>
                        <b>VOLUNTEER ID CARD</b>
                      </h5>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-4">
                      <img
                        src="./assets/img/idcard.jpg"
                        style={{
                          height: "100px",
                          width: "100px",
                          "border-radius": "10%",
                          border: "1px solid",
                        }}
                      />
                    </div>
                    <div className="col-sm-8 " style={{ textAlign: "left" }}>
                      <div className="col-sm-12 fw-bold">
                        <b>UserId :</b> {prefix}{userId}
                      </div>
                      <div className="col-sm-12 fw-bold">
                        <b>Name :</b> {UserName}
                      </div>
                      <div className="col-sm-12 fw-bold">
                        <b>Email : </b> {email}
                      </div>
                      <div className="col-sm-12 fw-bold">
                        <b>Mobile :</b> {mobile}
                      </div>
                    </div>
                  </div>
                  <div
                    className="row font-bold"
                    style={{ "font-size": "13px", "font-weight": "bold" }}
                  >
                    <div className="col-sm-6 text-left ">
                      <i>Issue Date</i>
                      <br />
                      <i>{joiningDate?.split(' ')[0]}</i>
                    </div>
                    <div className="col-sm-6 text-right">
                      <i>
                        <img
                          src="./assets/img/sign.png"
                          style={{ height: "30px" }}
                        />
                      </i>
                      <br />
                      <i>Authorised Signature</i>
                    </div>
                  </div>
                  <div
                    className="row font-bold text-light"
                    style={{
                      background: "#906097",
                      "border-radius": "0% 0% 10px 10px",
                    }}
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-2">
              <button
                id="btnprint"
                onClick={handlePrint}
                className="noPrint btn form-control btn-danger"
              >
                Print
              </button>
            </div>
            <div className="col-sm-5"></div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Idcard;
