import React from "react";
import BottomFooter from "../../../Dashboard/BottomFooter/BottomFooter.jsx";

const TransactionHistory = () => {
  return (
    <>
    
      <div className="report mt-top">
        <div className="card card-style">
          <div className="content mb-0">
           <h1 className="mb-3">Transaction History</h1>
            <div className="divider mb-4"></div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead  style={{background:"rgb(220, 155, 6)"}}>
                  <tr>
                    <th>#</th>
                    <th>Pin Number</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Used UserId</th>
                    <th>Used Date</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <BottomFooter />

     
    </>
  );
};

export default TransactionHistory;
